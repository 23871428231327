import { makeStyles } from "@material-ui/core/styles";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
// core components
import Pagination from "@mui/material/Pagination";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchStaff } from "reduxToolkit/features/user/UserSlice";
import {
  grayColor,
  primaryColor,
  successColor,
} from "../../../../assets/jss/material-dashboard-pro-react";
import {
  fetchPrograms,
  fetchProgramsByPage,
} from "../../../../reduxToolkit/features/program/ProgramSpecSlice";
import ErrorModal from "../../../Components/ErrorModal";
import ProgramCardDropdownButton from "../../../Components/ProgramCardDropdownButton";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);

const ProgramExcerpt = ({
  program,
  staffList,
  fetchProgramsCustomFunction,
  fetchProgramsByPageCustomFunction,
  data,
}) => {
  const { t } = useTranslation();
  const cardClasses = useCardStyles();

  return (
    <GridItem xs={12} sm={12} md={6} lg={4} xl={3}>
      <Card style={{ minHeight: "380px", maxHeight: "380px" }} cardHover>
        <CardHeader>
          <GridContainer
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <GridItem xs={10} sm={10} md={10}>
              <Link to={`/citrine/programSpec/${program.id}`}>
                <h4 className={cardClasses.cardProductTitle}>
                  {program && program.program_degree_name}
                </h4>
              </Link>
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
              {program && program.is_editor && program.state !== "Approved" && (
                <ProgramCardDropdownButton
                  id={program.id}
                  data={program}
                  state={program.state}
                  staffList={staffList}
                  fetchProgramsCustomFunction={fetchProgramsCustomFunction}
                  fetchProgramsByPageCustomFunction={
                    fetchProgramsByPageCustomFunction
                  }
                  params={data}
                />
              )}
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody smallCardBody>
          <GridContainer>
            <GridItem xs={4} sm={4} md={4}>
              <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                {t("Code")}:
              </div>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <div style={{ color: `${grayColor[1]}`, fontWeight: "normal" }}>
                {program && program.code}{" "}
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={4} sm={4} md={4}>
              <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                {t("Category")}:
              </div>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <div style={{ color: `${grayColor[1]}`, fontWeight: "normal" }}>
                {program &&
                  program.category_of_the_program_details &&
                  program.category_of_the_program_details[0] &&
                  program.category_of_the_program_details[0].name}
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={4} sm={4} md={4}>
              <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                {t("Total levels")}:
              </div>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <div style={{ color: `${grayColor[1]}`, fontWeight: "normal" }}>
                {program &&
                  program.total_level_details &&
                  program.total_level_details[0] &&
                  program.total_level_details[0].total_levels}
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={4} sm={4} md={4}>
              <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                {t("University")}:
              </div>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <div style={{ color: `${grayColor[1]}`, fontWeight: "normal" }}>
                {program &&
                  program.university_detail &&
                  program.university_detail[0] &&
                  program.university_detail[0].name}
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={4} sm={4} md={4}>
              <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                {t("Colleges")}:
              </div>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <div style={{ color: `${grayColor[1]}`, fontWeight: "normal" }}>
                {program.college_detail &&
                  program.college_detail.map((college, key) => {
                    return (
                      <div style={{ fontWeight: "normal" }} key={key}>
                        {" "}
                        {college.name}{" "}
                      </div>
                    );
                  })}
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={4} sm={4} md={4}>
              <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                {t("Departments")}:
              </div>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <div
                style={{
                  color: `${grayColor[1]}`,
                  display: "flex",
                  gap: "3px",
                }}
              >
                {program.department_detail &&
                  program.department_detail.map((department, key) => {
                    return (
                      <div key={key}>
                        <span>{department.name}</span>
                        <span>
                          {key + 1 !== program.department_detail.length
                            ? " ,"
                            : ""}
                        </span>
                      </div>
                    );
                  })}
              </div>
            </GridItem>
          </GridContainer>
          {program && program.coordinator_id != null && (
            <GridContainer>
              <GridItem xs={4} sm={4} md={4}>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}
                >
                  {t(" Coordinator")}:
                </div>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <div style={{ color: `${grayColor[1]}` }}>
                  <div>
                    {program && program.coordinator_detail[0].first_name}{" "}
                    {program && program.coordinator_detail[0].last_name}
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          )}
        </CardBody>
        <CardFooter>
          <GridContainer justifyContent="space-between">
            <GridItem>
              <span
                style={{
                  color: grayColor[1],
                  fontWeight: "bold",
                  marginLeft: 5,
                  marginBottom: 5,
                }}
              >
                V {program && program.version}
              </span>
            </GridItem>
            <GridItem>
              <span
                style={{
                  backgroundColor:
                    program.state === "Approved"
                      ? successColor[7]
                      : grayColor[4],
                  padding: 5,
                  marginRight: 5,
                  marginBottom: 5,
                  borderRadius: "10px",
                  color: grayColor[1],
                }}
              >
                {program && program.state}
              </span>
            </GridItem>
          </GridContainer>
        </CardFooter>
      </Card>
    </GridItem>
  );
};

export default function ProgramsPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const titleClasses = useTitleStyles();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [searchByDrop, setSearchByDrop] = useState("All Programs");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(9);

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const fetchProgramsByPageCustomFunction = async (data) => {
    try {
      await dispatch(fetchProgramsByPage(data)).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  const fetchProgramsCustomFunction = async () => {
    try {
      await dispatch(fetchPrograms()).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onhandleChangedrop = (text) => {
    setSearchByDrop(text);
  };

  useEffect(() => {
    dispatch(fetchStaff());
  }, []);

  let data = {
    page: page,
    records: rowsPerPage,
  };

  useEffect(() => {
    fetchProgramsByPageCustomFunction(data);
  }, [page]);

  useEffect(() => {
    fetchProgramsCustomFunction();
  }, []);

  const staffList = useSelector((state) => state.user.staffList);

  const programsByPage = useSelector(
    (state) => state.programSpec.programsByPage
  );

  const programsStatus = useSelector(
    (state) => state.programSpec.programsStatus
  );

  const programs = useSelector((state) => state.programSpec.programs);

  const stateFilteredContent = programs?.filter((program) => {
    if (searchByDrop === "Approved") {
      return program.state === "Approved";
    } else if (searchByDrop === "New") {
      return (
        program.state === "Draft" || program.state === "Waiting for approval"
      );
    } else if (searchByDrop === "Archived") {
      return program.state === "Archived";
    } else {
      return program;
    }
  });

  const stateFilteredSearchedContent = (
    <GridContainer>
      {stateFilteredContent
        ?.filter((item) => {
          return searchValue.toLowerCase() === ""
            ? item
            : item?.program_degree_name?.toLowerCase()?.includes(searchValue);
        })
        ?.map((program) => (
          <ProgramExcerpt
            key={program.id}
            program={program}
            staffList={staffList}
            fetchProgramsCustomFunction={fetchProgramsCustomFunction}
            fetchProgramsByPageCustomFunction={
              fetchProgramsByPageCustomFunction
            }
            data={data}
          />
        ))}
    </GridContainer>
  );

  const unfilteredContent = (
    <>
      <GridContainer>
        {programsByPage?.results?.map((program) => (
          <ProgramExcerpt
            key={program.id}
            program={program}
            staffList={staffList}
            fetchProgramsCustomFunction={fetchProgramsCustomFunction}
            fetchProgramsByPageCustomFunction={
              fetchProgramsByPageCustomFunction
            }
            data={data}
          />
        ))}
      </GridContainer>
      <div style={{ padding: 10 }}>
        <GridContainer justifyContent="flex-end">
          <GridItem>
            <Pagination
              color={"secondary"}
              count={Math.ceil(programsByPage?.count / rowsPerPage)}
              variant="outlined"
              page={page}
              onChange={handleChangePage}
            />
          </GridItem>
        </GridContainer>
      </div>
    </>
  );

  let content;

  if (programsStatus === "loading") {
    content = <div className="loader">{t("Loading...")}</div>;
  } else if (programsStatus === "succeeded") {
    if (searchValue || searchByDrop !== "All Programs") {
      content = stateFilteredSearchedContent;
    } else {
      content = unfilteredContent;
    }
  }

  return (
    <>
      <GridContainer
        alignItems="center"
        style={{
          borderTop: `1px solid  ${grayColor[15]}`,
          minHeight: "50px",
          maxHeight: "50px",
        }}
      >
        <GridItem>
          <Link to={`/citrine/programSpec`}>
            <span className={titleClasses.title}> {t("Programs")} </span>
          </Link>
        </GridItem>
      </GridContainer>

      <GridContainer alignItems="center" justifyContent="space-between">
        <GridItem>
          <Link to="/citrine/addProgramSpec">
            <Button color="primary">{t("Add")}</Button>
          </Link>
        </GridItem>
        <GridItem>
          <GridContainer alignItems="center">
            <GridItem xs={7} sm={7} md={7} lg={7}>
              <input
                placeholder="Search"
                style={{
                  backgroundColor: "#eeeeee",
                  border: "none",
                  borderBottom: "1px solid #BDBDBD",
                  marginRight: "50px",
                  width: "250px",
                  height: "30px",
                }}
                type="text"
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
              />
            </GridItem>
            <GridItem xs={5} sm={5} md={5} lg={5}>
              <GridContainer alignItems="center" justifyContent="flex-end">
                <CustomDropdown
                  buttonProps={{ color: "primary" }}
                  buttonText={searchByDrop}
                  dropPlacement="bottom-end"
                  dropdownList={[
                    {
                      text: "New",
                      onClickHandler() {
                        onhandleChangedrop("New");
                      },
                    },
                    {
                      text: "Approved",
                      onClickHandler() {
                        onhandleChangedrop("Approved");
                      },
                    },
                    {
                      text: "Archived",
                      onClickHandler() {
                        onhandleChangedrop("Archived");
                      },
                    },
                    {
                      text: "All Programs",
                      onClickHandler() {
                        onhandleChangedrop("All Programs");
                      },
                    },
                  ]}
                />
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>

      {content}

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </>
  );
}
