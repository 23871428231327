// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { SchoolRounded } from '@material-ui/icons'
import { unwrapResult } from '@reduxjs/toolkit'

import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import styles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.js'
import { MediaBaseUrl } from 'assets/utils/accessToken'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardIcon from 'components/Card/CardIcon.js'
import Button from 'components/CustomButtons/Button.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import {
  addNewCollege,
  collegeUpdate,
  fetchCollegeById,
  fetchColleges,
} from 'reduxToolkit/features/college/CollegesSlice'
import { fetchUniversities } from 'reduxToolkit/features/university/UniversitiesSlice'
import { fetchStaff } from 'reduxToolkit/features/user/UserSlice'
import ErrorModal from '../../../Components/ErrorModal'
import {
  Breadcrumb,
  Form,
  Input,
  InputNumber,
  message,
  notification,
  Select,
} from 'antd'
import Countries from '../../../../assets/utils/countries'


const useStyles = makeStyles(styles)

export default function CreateCollegePage({ match }) {
  const { t } = useTranslation()
  const { id } = match.params
  const [form] = Form.useForm()
  console.log('id', id)

  const isAddMode = isNaN(id)
  console.log('id from match is add mode', isAddMode)
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const useTitleStyles = makeStyles(titleStyles)

  const titleClasses = useTitleStyles()
  const [name, setName] = useState('')
  const [university_id, setUniversity_id] = useState('')
  const [mission, setMission] = useState('')
  const [vision, setVision] = useState('')
  const [goals, setGoals] = useState('')
  const [values, setValues] = useState('')
  const [phone_number, setPhone_number] = useState('')
  const [email, setEmail] = useState('')
  const [director_id, setDirector] = useState('')
  const [tech_contact, setTech_contact] = useState('')
  const [lms_contact, setLms_contact] = useState('')
  const [address, setAdress] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('')
  const [creator, setCreator] = useState('')
  const [postal_code, setPostal_code] = useState('')
  const [addRequestStatus, setAddRequestStatus] = useState('idle')
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }
  const onNameChanged = (e) => setName(e.target.value)
  const onUniversity_idChanged = (e) => setUniversity_id(e.target.value)
  const onMissionChanged = (e) => setMission(e.target.value)
  const onVisionChanged = (e) => setVision(e.target.value)
  const onGoalsChanged = (e) => setGoals(e.target.value)
  const onValuesChanged = (e) => setValues(e.target.value)
  const onPhone_numberChanged = (e) => setPhone_number(e.target.value)
  const onEmailChanged = (e) => setEmail(e.target.value)
  const onDirectorChanged = (e) => setDirector(e.target.value)

  const onAdressChanged = (e) => setAdress(e.target.value)
  const onCityChanged = (e) => setCity(e.target.value)
  const onCountryChanged = (e) => setCountry(e.target.value)
  const onPostal_codeChanged = (e) => setPostal_code(e.target.value)

  const personsStatus = useSelector((state) => state.user.staffStatus)
  useEffect(() => {
    if (personsStatus === 'idle') {
      dispatch(fetchStaff())
    }
    dispatch(fetchUniversities())
  }, [])
  const persons = useSelector((state) => state.user.staffList)
  console.log('persons', persons)

  const dispatch = useDispatch()
  const universities = useSelector((state) => state.university.universities)
  console.log('universities', universities)
  const { Option } = Select
  const canSave =
    [
      name,
      mission,
      vision,
      goals,
      values,
      university_id,
      phone_number,
      director_id,
      email,
      address,
      city,
      country,
      postal_code,
    ].every(Boolean) && addRequestStatus === 'idle'
  const history = useHistory()

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }
  const onFinish = async (values) => {
    try {
      setAddRequestStatus('pending')
      const resultAction = await dispatch(addNewCollege(values))
      unwrapResult(resultAction)
      openNotificationWithIcon('success', `Added with success`)
      dispatch(fetchColleges())
      history.push('/citrine/organizations/colleges/')
      form.resetFields()
    } catch (err) {
      setHandledError(err)
      handleOpenErrorModal()
    } finally {
      setAddRequestStatus('idle')
    }
  }
  const onSaveCollegeClicked = async () => {
    // if (canSave) {
    try {
      setAddRequestStatus('pending')
      console.log('onsave')
      const resultAction = await dispatch(
        addNewCollege({
          name,
          university_id,
          mission,
          vision,
          goals,
          values,
          phone_number,
          email,
          director_id,
          address,
          city,
          country,
          postal_code,
          lms_contact,
          tech_contact,
        }),
      )
      unwrapResult(resultAction)
      setName('')
      setUniversity_id('')
      setMission('')
      setVision('')
      setGoals('')
      setValues('')
      setPhone_number('')
      setEmail('')
      setDirector('')
      setLms_contact('')
      setTech_contact('')
      setAdress('')
      setCity('')
      setCountry('')
      setPostal_code('')
      dispatch(fetchColleges())
      history.push('/citrine/organizations/colleges/')
    } catch (err) {
      console.log('college', addNewCollege)
      console.error('Failed to save the college: ', err)
    } finally {
      setAddRequestStatus('idle')
    }
  }

  const onUpdateCollegeClicked = async () => {
    // if (canSave) {
    try {
      setAddRequestStatus('pending')
      console.log('onupdate')
      let university = university_id
      const resultAction = await dispatch(
        collegeUpdate({
          id: id,
          modified: {
            name,
            university,
            mission,
            vision,
            goals,
            values,
            phone_number,
            email,
            director_id,
            address,
            city,
            country,
            postal_code,
            lms_contact,
            tech_contact,
            creator,
          },
        }),
      )
      unwrapResult(resultAction)
      setName('')
      setUniversity_id('')
      setMission('')
      setVision('')
      setGoals('')
      setValues('')
      setPhone_number('')
      setEmail('')
      setDirector('')
      setLms_contact('')
      setTech_contact('')
      setAdress('')
      setCity('')
      setCountry('')
      setPostal_code('')
      dispatch(fetchColleges())
      history.push('/citrine/organizations/colleges/')
    } catch (err) {
      console.log('college', collegeUpdate)
      console.error('Failed to save the college: ', err)
    } finally {
      setAddRequestStatus('idle')
    }
  }

  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchCollegeById(id))
    }
  }, [id])

  const college = useSelector((state) => state.college.college)
  console.log('college ', college)

  useEffect(() => {
    if (!isAddMode) {
      setName(college && college.name)
      setUniversity_id(college && college.university_id)
      setMission(college && college.mission)
      setVision(college && college.vision)
      setGoals(college && college.goals)
      setPhone_number(college && college.phone_number)
      setEmail(college && college.email)
      setDirector(college && college.director_id)
      setLms_contact(college && college.lms_contact)
      setTech_contact(college && college.tech_contact)
      setAdress(college && college.address)
      setCity(college && college.city)
      setCountry(college && college.country)
      if (college && college.postal_code === 'nan') {
        setPostal_code(null)
      } else {
        setPostal_code(college && college.postal_code)
      }
      setCreator(college && college.creator && college.creator.id)
      setValues(college && college.values)
    }
  }, [college, dispatch])
  function onChange(value) {
    console.log(`selected ${value}`)
    setUniversity_id(value)
  }
  function onChangeDirector(value) {
    console.log(`selected ${value}`)
    setDirector(value)
  }
  function onBlur() {
    console.log('blur')
  }

  function onFocus() {
    console.log('focus')
  }

  function onSearch(val) {
    console.log('search:', val)
  }
  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  return (
    <div>
      {isAddMode && (
        <div>
          <GridContainer xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <SchoolRounded />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  <Breadcrumb separator=">">
                    <Breadcrumb.Item>
                      <Link to={`/citrine/organizations`}>
                        <span> {t('Organizations')} </span>
                      </Link>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                      <Link to={`/citrine/organizations/colleges`}>
                        <span> {t('Colleges')} </span>
                      </Link>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                      <span> {t('Create')} </span>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                  {/* <span className={titleClasses.title}>
                    {' '}
                    <Link to={`/citrine/organizations`}>
                      {t('Organizations')}
                    </Link>{' '}
                    <Link to={`/citrine/organizations/colleges`}>
                      {t(' /Colleges')}
                    </Link>{' '}
                    {t('/Create')}{' '}
                  </span> */}
                </h4>
              </CardHeader>
              <CardBody>
                <Form
                  name="basic"
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  scrollToFirstError
                >
                  <GridContainer
                    xs={12}
                    sm={12}
                    md={12}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <GridItem xs={12} sm={12} md={6}>
                      <Form.Item
                        label={t('University')}
                        name="university_id"
                        rules={[
                          {
                            required: true,
                            message: 'Please select University',
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ width: 400 }}
                          optionFilterProp="children"
                          onFocus={onFocus}
                          onBlur={onBlur}
                          onSearch={onSearch}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {universities &&
                            universities.map((university) => (
                              <option key={university.id} value={university.id}>
                                {university.name}
                              </option>
                            ))}
                        </Select>
                      </Form.Item>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <Form.Item
                        label={t('Name')}
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter a name!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <Form.Item
                        label={t('Mission')}
                        name="mission"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter a mission!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <Form.Item
                        label={t('Vision')}
                        name="vision"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter a vision!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <Form.Item
                        label={t('Goals')}
                        name="goals"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter goals!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <Form.Item
                        label={t('Values')}
                        name="values"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter values!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <Form.Item
                        label={t('Director')}
                        name="director_id"
                        rules={[
                          {
                            required: false,
                            message: 'Please director id',
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ width: 400 }}
                          optionFilterProp="children"
                          onFocus={onFocus}
                          onBlur={onBlur}
                          onSearch={onSearch}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {/* {persons &&
                            persons.map((p) => (
                              <Option key={p.id} value={p.id}>
                                {`${
                                  p.person_details &&
                                  p.person_details[0].first_name
                                } ${
                                  p.person_details &&
                                  p.person_details[0].last_name
                                } `}
                              </Option>
                            ))} */}
                          {persons &&
                            persons.map((p) => (
                              <Option key={p.id} value={p.id}>
                                {!p?.person_details[0]?.avatar ? (
                                  <span
                                    style={{
                                      backgroundColor: 'rgb(143, 102, 130)',
                                      width: '23px',
                                      height: '23px',
                                      color: 'white',
                                      borderRadius: '50%',
                                      paddingLeft: '7px',
                                      marginRight: '15px',
                                      display: 'inline-block',
                                      lineHeight: '23px',
                                    }}
                                  >
                                    {p?.person_details[0]?.first_name
                                      .charAt(0)
                                      .toUpperCase() + '     '}
                                  </span>
                                ) : (
                                  <img
                                    style={{
                                      width: '20px',
                                      height: '20px',
                                    }}
                                    src={`${MediaBaseUrl}${p?.person_details[0]?.avatar}`}
                                  />
                                )}
                                {`${p.person_details &&
                                  p.person_details[0].first_name
                                  } ${p.person_details &&
                                  p.person_details[0].last_name
                                  } `}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <Form.Item
                        label={t('LMS support contact')}
                        name="lms_contact"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter a lms_contact!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <Form.Item
                        label={t('General technical support contact')}
                        name="tech_contact"
                        rules={[
                          {
                            required: false,
                            message: 'Please enter a tech_contact!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <Form.Item
                        name="phone_number"
                        label="Phone number"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter a phone number!',
                          },
                        ]}
                      >
                        <InputNumber
                          autoSize={true}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <Form.Item
                        name="email"
                        label="Email address"
                        rules={[
                          {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                          },
                          {
                            required: true,
                            message: 'Please input your E-mail!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <Form.Item
                        name="address"
                        label="address"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter an address!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <Form.Item
                        name="city"
                        label="City"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter a city!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <Form.Item
                        label={t('Country')}
                        name="country"
                        rules={[
                          {
                            required: false,
                            message: 'Please select country',
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ width: 400 }}
                          optionFilterProp="children"
                          onFocus={onFocus}
                          onBlur={onBlur}
                          onSearch={onSearch}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {Countries.map((c) => (
                            <Option key={c.name} value={c.name}>
                              {`${c.code}/ ${c.name}`}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <Form.Item
                        name="postal_code"
                        label="Postal Code"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter a postal_code!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="flex-end">
                    <GridItem>
                      <Form.Item>
                        <Button
                          onClick={() =>
                            history.push('/citrine/organizations/colleges/')
                          }
                          style={{ marginRight: '20px' }}
                          color="danger"
                        >
                          {t('Cancel')}
                        </Button>
                        <Button
                          type="submit"
                          color="info"
                          className={classes.updateProfileButton}
                        >
                          {t('Save')}
                        </Button>
                      </Form.Item>
                    </GridItem>
                  </GridContainer>
                </Form>
              </CardBody>
              {/* <CardBody>
                  <form>
                    <GridContainer xs={12} sm={12} md={12} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                      <GridItem xs={12} sm={12} md={6}>

                        <label>{t('University')}</label>
                        <Select
                          showSearch
                          style={{ width: 400 }}
                          optionFilterProp="children"
                          name="university_id"
                          value={university_id}
                          onChange={onChange}
                          onFocus={onFocus}
                          onBlur={onBlur}
                          onSearch={onSearch}
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }

                        >
                          {universities && universities.map((university) => (
                            <option key={university.id} value={university.id}>
                              {university.name}
                            </option>
                          ))}

                        </Select>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText={t('Name ')}
                          id="name"
                         
                          type="text"
                          name="name"
                          value={name}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a name!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: event => {
                              setName(event.target.value);
                            }
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText={t('Mission')}
                          id="mission"
                          value={mission}
                          
                          type="text"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a mission!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: event => {
                              setMission(event.target.value);
                            }
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>

                        <CustomInput
                          labelText={t('Vision ')}
                          id="vision"
                          value={vision}
                          
                          type="text"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a vision!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: event => {
                              setVision(event.target.value);
                            }
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>

                        <CustomInput
                          labelText={t('Goals ')}
                          id="goals"
                          value={mission}
                         
                          type="text"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter goals!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: event => {
                              setGoals(event.target.value);
                            }
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>

                        <CustomInput
                          labelText={t('Values ')}
                          id="values"
                          value={values}
                         
                          type="text"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter values!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: event => {
                              setValues(event.target.value);
                            }
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>

                        <label>{t('Director')}</label>
                        <Select
                          showSearch
                          style={{ width: 400 }}
                          optionFilterProp="children"
                          name="director_id"
                          value={director_id}
                          onChange={onChangeDirector}
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }

                        >
                          {persons && persons.map((p) => (
                            <Option key={p.id} value={p.id}>
                              {`${p.person_details && p.person_details[0].first_name} ${p.person_details && p.person_details[0].last_name} `}
                            </Option>
                          ))}

                        </Select>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6} >
                        <CustomInput
                          labelText={t('LMS support contact ')}
                          id="lms_contact"
                          value={lms_contact}
                       
                          type="text"
                          rules={[
                            {
                              required: false,
                              message: 'Please enter a lms_contact!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: event => {
                              setLms_contact(event.target.value);
                            },
                            value: lms_contact
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText={t('General technical support contact ')}
                          id="tech_contact"
                          value={tech_contact}
                          type="text"
                          rules={[
                            {
                              required: false,
                              message: 'Please enter a tech_contact!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: event => {
                              setTech_contact(event.target.value);
                            },
                            value: tech_contact
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText={t('Phone number ')}
                          id="Phone_number"
                          type="number"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a phone number!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: event => {
                              setPhone_number(event.target.value);
                            }
                          }}
                        />

                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText={t('Email address ')}
                          id="Email"
                          value={email}
                          type="text"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter an email!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: event => {
                              setEmail(event.target.value);
                            }
                          }}
                        />



                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>

                        <CustomInput
                          labelText={t('Adress ')}
                          id="Adress"
                          value={address}
                          type="text"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter an adress!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: event => {
                              setAdress(event.target.value);
                            }
                          }}
                        />

                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText={t('City ')}
                          id="city"
                          value={city}
                          
                          type="text"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a city!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: event => {
                              setCity(event.target.value);
                            }
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>

                        <CustomInput
                          labelText={t('Country ')}
                          id="Country"
                          value={country}
                         
                          type="text"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a country!',
                            },
                          ]}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: event => {
                              setCountry(event.target.value);
                            }
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>

                        <CustomInput
                          labelText={t('Postal Code ')}
                          id="Postal_code"
                          value={postal_code}
                          formControlProps={{
                            fullWidth: true
                          }}
                          
                          type="text"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a postal code!',
                            },
                          ]}
                          inputProps={{
                            disabled: false,
                            onChange: event => {
                              setPostal_code(event.target.value);
                            }
                          }}
                        />
                      </GridItem>
                      <GridItem>
                        <Button color="info"
                          className={classes.updateProfileButton}
                          onClick={onSaveCollegeClicked}
                        >
                          {t('Save')}
                        </Button></GridItem>


                    </GridContainer>
                  </form>

                </CardBody> */}
            </Card>
          </GridContainer>
          <ErrorModal
            open={openErrorModal}
            handleClose={handleCloseErrorModal}
            handledError={handledError}
          />
        </div>
      )}

      {!isAddMode && college && (
        <div>
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="info" icon>
                    <CardIcon color="info">
                      <SchoolRounded />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                      <span className={titleClasses.title}>
                        <Link to={`/citrine/organizations`}>
                          {t('Organizations')}
                        </Link>{' '}
                        <Link to={`/citrine/organizations/colleges`}>
                          {t(' /Colleges')}
                        </Link>
                        {t('/Update:')} {name}{' '}
                      </span>
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <form>
                      <GridContainer
                        xs={12}
                        sm={12}
                        md={12}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <GridItem xs={6} sm={6} md={6}>
                          <GridItem>
                            <label>{t('University ')} </label>
                            <Select
                              showSearch
                              style={{ width: 200 }}
                              optionFilterProp="children"
                              name="university_id"
                              value={university_id}
                              onChange={onChange}
                              onFocus={onFocus}
                              onBlur={onBlur}
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {universities &&
                                universities.map((university) => (
                                  <option
                                    key={university.id}
                                    value={university.id}
                                  >
                                    {university.name}
                                  </option>
                                ))}
                            </Select>
                            ,
                          </GridItem>

                          <CustomInput
                            labelText={t('Name ')}
                            id="name"
                            //onChange={onNameChanged}
                            type="text"
                            name="name"
                            value={name}
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a name!',
                              },
                            ]}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              //disabled: false,
                              onChange: (event) => {
                                setName(event.target.value)
                              },
                              value: name,
                            }}
                          />

                          <CustomInput
                            labelText={t('Mission')}
                            id="mission"
                            value={mission}
                            //     onChange={onMissionChanged}
                            type="text"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a mission!',
                              },
                            ]}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: false,
                              onChange: (event) => {
                                setMission(event.target.value)
                              },
                              value: mission,
                            }}
                          />

                          <CustomInput
                            labelText={t('Vision ')}
                            id="vision"
                            value={vision}
                            //     onChange={onMissionChanged}
                            type="text"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a vision!',
                              },
                            ]}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: false,
                              onChange: (event) => {
                                setVision(event.target.value)
                              },
                              value: vision,
                            }}
                          />

                          <CustomInput
                            labelText={t('Goals ')}
                            id="goals"
                            value={mission}
                            //     onChange={onMissionChanged}
                            type="text"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter goals!',
                              },
                            ]}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: false,
                              onChange: (event) => {
                                setGoals(event.target.value)
                              },
                              value: goals,
                            }}
                          />

                          <CustomInput
                            labelText={t('Values ')}
                            id="values"
                            value={values}
                            //     onChange={onMissionChanged}
                            type="text"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter values!',
                              },
                            ]}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: false,
                              onChange: (event) => {
                                setValues(event.target.value)
                              },
                              value: values,
                            }}
                          />

                          <CustomInput
                            labelText={t('Phone number ')}
                            id="Phone_number"
                            // onChange={onPhoneNumberChanged}
                            type="number"
                            // value={Phone_number}
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a phone number!',
                              },
                            ]}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: false,
                              onChange: (event) => {
                                setPhone_number(event.target.value)
                              },
                              value: phone_number,
                            }}
                          />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText={t('Email address ')}
                            id="Email"
                            value={email}
                            //     onChange={onEmailChanged}
                            type="text"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter an email!',
                              },
                            ]}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: false,
                              onChange: (event) => {
                                setEmail(event.target.value)
                              },
                              value: email,
                            }}
                          />

                          <GridItem xs={12} sm={12} md={6}>
                            <label>{t('Director')}</label>
                            <Select
                              showSearch
                              style={{ width: 400 }}
                              optionFilterProp="children"
                              name="director_id"
                              value={director_id}
                              onChange={onChangeDirector}
                            // filterOption={(input, option) =>
                            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            // }
                            >
                              {persons &&
                                persons.map((p) => (
                                  <Option key={p.id} value={p.id}>
                                    {!p?.person_details[0]?.avatar ? (
                                      <span
                                        style={{
                                          backgroundColor: 'rgb(143, 102, 130)',
                                          width: '23px',
                                          height: '23px',
                                          color: 'white',
                                          borderRadius: '50%',
                                          paddingLeft: '7px',
                                          marginRight: '15px',
                                          display: 'inline-block',
                                          lineHeight: '23px',
                                        }}
                                      >
                                        {p?.person_details[0]?.first_name
                                          .charAt(0)
                                          .toUpperCase() + '     '}
                                      </span>
                                    ) : (
                                      <img
                                        style={{
                                          width: '20px',
                                          height: '20px',
                                        }}
                                        src={`${MediaBaseUrl}${p?.person_details[0]?.avatar}`}
                                      />
                                    )}
                                    {`${p.person_details &&
                                      p.person_details[0].first_name
                                      } ${p.person_details &&
                                      p.person_details[0].last_name
                                      } `}
                                  </Option>
                                ))}
                            </Select>
                          </GridItem>
                          <CustomInput
                            labelText={t('LMS support contact ')}
                            id="lms_contact"
                            value={lms_contact}
                            //    onChange={onDirectorChanged}
                            type="text"
                            rules={[
                              {
                                required: false,
                                message: 'Please enter a lms_contact!',
                              },
                            ]}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: false,
                              onChange: (event) => {
                                setLms_contact(event.target.value)
                              },
                              value: lms_contact,
                            }}
                          />

                          <CustomInput
                            labelText={t('General technical support contact ')}
                            id="tech_contact"
                            value={tech_contact}
                            //    onChange={onDirectorChanged}
                            type="text"
                            rules={[
                              {
                                required: false,
                                message: 'Please enter a tech_contact!',
                              },
                            ]}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: false,
                              onChange: (event) => {
                                setTech_contact(event.target.value)
                              },
                              value: tech_contact,
                            }}
                          />

                          <CustomInput
                            labelText={t('Adress ')}
                            id="Adress"
                            value={address}
                            //       onChange={onAdressChanged}
                            type="text"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter an adress!',
                              },
                            ]}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: false,
                              onChange: (event) => {
                                setAdress(event.target.value)
                              },
                              value: address,
                            }}
                          />

                          <CustomInput
                            labelText={t('City ')}
                            id="City"
                            value={city}
                            //         onChange={onCityChanged}
                            type="text"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a city!',
                              },
                            ]}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: false,
                              onChange: (event) => {
                                setCity(event.target.value)
                              },
                              value: city,
                            }}
                          />

                          <CustomInput
                            labelText={t('Country ')}
                            id="Country"
                            value={country}
                            //         onChange={onCountryChanged}
                            type="text"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a country!',
                              },
                            ]}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: false,
                              onChange: (event) => {
                                setCountry(event.target.value)
                              },
                              value: country,
                            }}
                          />

                          <CustomInput
                            labelText={t('Postal Code ')}
                            id="Postal_code"
                            value={postal_code}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            //       onChange={onPostalCodeChanged}
                            type="text"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter a postal code!',
                              },
                            ]}
                            inputProps={{
                              disabled: false,
                              onChange: (event) => {
                                setPostal_code(event.target.value)
                              },
                              value: postal_code,
                            }}
                          />
                        </GridItem>

                        <Button
                          color="info"
                          className={classes.updateProfileButton}
                          onClick={onUpdateCollegeClicked}
                        >
                          {t('Update')}
                        </Button>
                      </GridContainer>
                    </form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      )}
    </div>
  )
}
