// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import {
  Breadcrumb,
  Form,
  Input,
  InputNumber,
  message,
  notification,
  Select,
} from 'antd'
import 'antd/dist/antd.css'
import 'assets/css/styles.css'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import styles from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardIcon from 'components/Card/CardIcon.js'
import Button from 'components/CustomButtons/Button.js'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { fetchColleges } from 'reduxToolkit/features/college/CollegesSlice'
import { fetchDepartments } from 'reduxToolkit/features/department/DepartmentsSlice'
import { fetchLevels } from 'reduxToolkit/features/level/LevelSlice'
import { fetchTypes } from 'reduxToolkit/features/type/TypeSlice'
import { fetchUniversities } from 'reduxToolkit/features/university/UniversitiesSlice'
import { fetchYearSemesters } from 'reduxToolkit/features/yearSemester/YearSemesterSlice'
import {
  grayColor,
  infoColor,
} from '../../../../assets/jss/material-dashboard-pro-react'
// @material-ui/icons
import {
  addNewCourse,
  fetchCourses,
} from '../../../../reduxToolkit/Course/coursesSpecSlice'
import { fetchAreas } from '../../../../reduxToolkit/features/area/AreaSpecSlice'
import { fetchSubAreas } from '../../../../reduxToolkit/features/subarea/SubAreaSpecSlice'
import { fetchUsers } from '../../../../reduxToolkit/features/user/UserSlice'
import { fetchStaff } from "../../../../reduxToolkit/features/user/UserSlice"

import ErrorModal from '../../../Components/ErrorModal'

const useStyles = makeStyles(styles)

const useTitleStyles = makeStyles(titleStyles)

export default function CreateCourseSpec(props) {

  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { TextArea } = Input
  const classes = useStyles()
  const titleClasses = useTitleStyles()

  const [title, setTitle] = useState('')
  const [code, setCode] = useState('')
  const [academic_year, setAcademic_year] = useState('')
  const [level_id, setLevel] = useState('')
  const [category, setCategory_id] = useState('')
  const [type, setType] = useState('')
  const [credit_hours, setCredit_hours] = useState('')
  const [description, setDescription] = useState('')
  const [main_objectives, setMain_objectives] = useState('')
  const [student_academic_counseling_and_support, setStudent_academic_counseling_and_support,] = useState('student_academic_counseling_and_support')
  const [follow_up, setFollow_up,] = useState('follow_up')
  const [assessment_details_and_rubics, setAssessment_details_and_rubrics,] = useState('')
  const [coordinator_id, setCoordinator_id] = useState()
  const [department_ids, setDepartment_id] = useState([])
  const [college_ids, setCollege_id] = useState([])
  const [institution_id, setInstitution_id] = useState('')
  const [prerequisites_ids, setPrerequisites_ids] = useState([])
  const [co_requisites_ids, setCo_requisites_ids] = useState([])
  const [areaSpec_ids, setAreaSpec_ids] = useState([])
  const [subAreaSpec_ids, setSubAreaSpec_ids] = useState([])
  const [number_of_weeks, setNumber_of_weeks] = useState('')
  const [number_of_days, setNumber_of_days] = useState('')
  const [number_of_hours, setNumber_of_hours] = useState('')
  const [program_structure, setProgram_structure] = useState('')
  const [submission_of_assessment, setSubmission_of_assessment] = useState('')
  const [students_development_and_participation, setStudent_dedevelopment_and_participation,] = useState('')
  const [yearSemesterConfig_id, setYearSemesterConfig_id] = useState('')
  const [field, setField] = useState(false)
  const [addRequestStatus, setAddRequestStatus] = useState('idle')

  const dispatch = useDispatch()
  const areasStatus = useSelector((state) => state.areaSpec.areasStatus)
  const subAreasStatus = useSelector((state) => state.subAreaSpec.subAreasStatus,)
  const typesStatus = useSelector((state) => state.type.typesStatus)
  const levelsStatus = useSelector((state) => state.level.levelsStatus)
  const yearSemesterStatus = useSelector((state) => state.yearSemesters.yearSemesters,)
  const universitiesStatus = useSelector((state) => state.university.universitiesStatus,)
  const departmentsStatus = useSelector((state) => state.department.departmentsStatus,)
  const collegesStatus = useSelector((state) => state.college.collegesStatus)
  const coursesStatus = useSelector((state) => state.CourseSpec.coursesSpecificationStatus,)
  const staffStatus = useSelector((state) => state.user.staffStatus)
  const universities = useSelector((state) => state.university.universities)
  const departments = useSelector((state) => state.department.departments)
  const courses = useSelector((state) => state.CourseSpec.coursesSpecification)
  const areas = useSelector((state) => state.areaSpec.areas)
  const subAreas = useSelector((state) => state.subAreaSpec.subAreas)
  const types = useSelector((state) => state.type.types)
  const levels = useSelector((state) => state.level.levels)
  const colleges = useSelector((state) => state.college.colleges)
  const yearSemesters = useSelector((state) => state.yearSemesters.yearSemesters,)
  const listStaff = useSelector((state) => state.user.staffList)
  const { currentUser } = useSelector((state) => state.user);

  const history = useHistory()

  const [selectedSubAreas, setSelectedSubAreas] = useState([])
  const [selectedAreas, setSelectedAreas] = useState([])
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [handledError, setHandledError] = useState('')

  useEffect(() => {
    if (universitiesStatus === 'idle') {
      dispatch(fetchUniversities())
    }
    if (departmentsStatus === 'idle') {
      dispatch(fetchDepartments())
    }
    if (collegesStatus === 'idle') {
      dispatch(fetchColleges())
    }
    if (areasStatus === 'idle') {
      dispatch(fetchAreas())
    }
    if (subAreasStatus === 'idle') {
      dispatch(fetchSubAreas())
    }
    if (typesStatus === 'idle') {
      dispatch(fetchTypes())
    }
    if (levelsStatus === 'idle') {
      dispatch(fetchLevels())
    }
    if (yearSemesters === 'idle') {
      dispatch(fetchYearSemesters())
    }
    if (coursesStatus === 'idle') {
      dispatch(fetchCourses())
    }
    if (staffStatus === 'idle') {
      dispatch(fetchStaff())
    }
    dispatch(fetchUsers())
  }, [])

  useEffect(() => {
    setCoordinator_id(currentUser?.staff_details?.[0]?.id)
  }, []);

  useEffect(() => {
    const preselectedSubAreas =
      subAreas &&
      subAreas.filter((subArea) => subAreaSpec_ids.indexOf(subArea.id) !== -1)
    setSelectedSubAreas(preselectedSubAreas)
    const preAreaSpec_ids = [
      ...new Set(
        preselectedSubAreas.map((selectedSubArea) => {
          return selectedSubArea.areaSpec_id
        }),
      ),
    ]
    setAreaSpec_ids(preAreaSpec_ids)
    const preselectedAreas =
      areas && areas.filter((area) => preAreaSpec_ids.indexOf(area.id) !== -1)
    setSelectedAreas(preselectedAreas)
  }, [subAreaSpec_ids])

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const onFinishFailed = (errorInfo) => {
    message.error('Submit failed!')
  }

  const onFinish = async (values) => {
    let course
    course = { ...values, areaSpec_ids, coordinator_id, student_academic_counseling_and_support, follow_up }
    const resultAction = await dispatch(addNewCourse(course))
    if (addNewCourse.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      const course = resultAction.payload
      openNotificationWithIcon('success', `Added ${course.title}`)
      history.push('/citrine/CourseSpecificationList')
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        setHandledError(resultAction.payload)
        handleOpenErrorModal()
      } else {
        setHandledError(resultAction.error)
        handleOpenErrorModal()
      }
    }
  }

  const onChangeSubArea = (ids) => {
    setSubAreaSpec_ids(ids)
  }

  const onChangeUniversity = (value) => {
    setInstitution_id(value)
  }

  const onChangeDepartment = (value) => {
    setDepartment_id(value)
  }

  const onChangeCollege = (value) => {
    setCollege_id(value)
  }

  const onChangeYearSemester = (value) => {
    setYearSemesterConfig_id(value)
  }

  const onChangelevel = (value) => {
    setLevel(value)
  }

  const onChangeCategory = (value) => {
    setCategory_id(value)
  }

  const onChangePrerequisites_ids = (value) => {
    setPrerequisites_ids(value)
  }

  const onChangeCo_requisites_ids = (value) => {
    setCo_requisites_ids(value)
  }

  const onChangeType = (value) => {
    setType(value)
    if (value === 'Field Experience') {
      setField(true)
    } else {
      setField(false)
    }
  }

  const onChangeProgramStructure = (value) => {
    setProgram_structure(value)
  }

  const { Option } = Select

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link
                style={{ color: 'orange' }}
                to={`/citrine/CourseSpecificationList/`}
              >
                {t('Courses List')}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <Form
        name="basic"
        form={form}
        layout="vertical"
        initialValues={{ prerequisites_ids: [], co_requisites_ids: [] }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError
      >
        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <LibraryBooksIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Create a new course</h4>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={10}>
            <h3>
              <small style={{ color: `${infoColor[0]}` }}>
                Course General Information
              </small>
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <Form.Item
                      label={t('Code')}
                      name="code"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's code",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={8}>
                    <Form.Item
                      label={t('Title')}
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's title",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t('Credit hours')}
                      name="credit_hours"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's credit hours",
                        },
                      ]}
                    >
                      <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      name="number_of_weeks"
                      label="Number of weeks "
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <InputNumber autoSize={true} style={{ width: '100%' }} />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Form.Item
                      label={t('Sub Area')}
                      name="subAreaSpec_ids"
                      rules={[
                        {
                          required: false,
                          message: "Please input the course's area and subarea",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        optionFilterProp="children"
                        onChange={onChangeSubArea}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {subAreas &&
                          subAreas.map((subarea) => (
                            <Option key={subarea.id} value={subarea.id}>
                              {`${subarea.areaSpec_detail.name} /  ${subarea.name}`}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Form.Item
                      label={t('Level')}
                      name="level_id"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's level",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        onChange={onChangelevel}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {selectedAreas &&
                          selectedAreas.map(
                            (area) =>
                              area.levels &&
                              area.levels.map((level) => (
                                <Option key={level.id} value={level.id}>
                                  {level.tag}
                                </Option>
                              )),
                          )}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Form.Item
                      label={t('Category')}
                      name="category"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's category",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        onChange={onChangeCategory}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option value="Required">Required</Option>
                        <Option value="Elective">Elective</Option>
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t('Prerequisites')}
                      name="prerequisites_ids"
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={onChangePrerequisites_ids}
                      >
                        {courses.map((course) => (
                          <Option key={course.id} value={course.id}>
                            {course.code}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t('Co_requisites')}
                      name="co_requisites_ids"
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={onChangeCo_requisites_ids}
                      >
                        {courses.map((course) => (
                          <Option key={course.id} value={course.id}>
                            {course.code}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t('Type')}
                      name="type"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's type",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        onChange={onChangeType}
                      >
                        <Option value="Conventional Course">
                          Conventional Course
                        </Option>
                        <Option value="Final Year Project">
                          Final Year Project
                        </Option>
                        <Option value="Senior Design">Senior Design</Option>
                        <Option value="Field Experience">
                          Field Experience
                        </Option>
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t('Program Structure')}
                      name="program_structure'"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please input the course's Program Structure",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={onChangeProgramStructure}
                      >
                        <Option value={'Institution Requirements'}>
                          {'Institution Requirements'}
                        </Option>
                        <Option value={'College Requirements'}>
                          {'College Requirements'}
                        </Option>
                        <Option value={'Program Requirements'}>
                          {'Program Requirements'}
                        </Option>
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t('Description')}
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's description",
                        },
                      ]}
                    >
                      <TextArea rows={4} placeholder="" maxLength={100000} />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t('Main objectives')}
                      name="main_objectives"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's main objectives",
                        },
                      ]}
                    >
                      <TextArea rows={4} placeholder="" maxLength={100000} />
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={10}>
            {field && (
              <div>
                <h3>
                  <small style={{ color: `${infoColor[0]}` }}>
                    Dates and times allocation of field experience activities
                  </small>
                </h3>
              </div>
            )}
          </GridItem>
          <GridItem xs={12} sm={12} md={10}>
            {field && (
              <div>
                <Card>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <Form.Item
                          name="number_of_days"
                          label="Number of days"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <InputNumber
                            autoSize={true}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <Form.Item
                          name="number_of_hours"
                          label="Number of hours"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <InputNumber
                            autoSize={true}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t(
                            'Decision-making procedures for identifying appropriate locations for field experience',
                          )}
                          name="decision_making"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please input the course's Decision-making procedures for identifying appropriate locations for field experience",
                            },
                          ]}
                        >
                          <TextArea rows={4} placeholder="" maxLength={100} />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Supervision and Follow-up Mechanism')}
                          name="follow-up"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please input the course's Supervision and Follow-up Mechanism",
                            },
                          ]}
                        >
                          <TextArea rows={4} placeholder="" maxLength={100} />
                        </Form.Item>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </div>
            )}
          </GridItem>
          <GridItem xs={12} sm={12} md={10}>
            <h3>
              <small style={{ color: `${infoColor[0]}` }}>
                Institutional Information
              </small>
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <Form.Item
                      label={t('Institution')}
                      name="institution_id"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's institution",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        onChange={onChangeUniversity}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {universities.map((university) => (
                          <Option key={university.id} value={university.id}>
                            {university.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Form.Item
                      label={t('Department')}
                      name="department_ids"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's department",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        mode="multiple"
                        optionFilterProp="children"
                        onChange={onChangeDepartment}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {departments.map((department) => (
                          <Option key={department.id} value={department.id}>
                            {department.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Form.Item
                      label={t('Colleges')}
                      name="college_ids"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's colleges",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        optionFilterProp="children"
                        onChange={onChangeCollege}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {colleges.map((c) => (
                          <Option key={c.id} value={c.id}>
                            {c.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={10}></GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={10}>
            <GridContainer justify="flex-end">
              <GridItem>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="submit" color="primary">
                    {t('Save')}
                  </Button>
                </Form.Item>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Form>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
