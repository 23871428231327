import { makeStyles } from "@material-ui/core/styles";

import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import Card from "components/Card/Card.js";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader.js";
// core components
import { Modal } from "antd";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchProgramsByPage } from "reduxToolkit/features/program/ProgramsSlice";
import { fetchStaff } from "reduxToolkit/features/user/UserSlice";
import DropDownButtonAnnualProgram_PES_SES from "views/Components/DropDownButtonAnnualProgram_PES_SES";
import {
  grayColor,
  successColor,
} from "../../../../assets/jss/material-dashboard-pro-react";

import { primaryColor } from "../../../../assets/jss/material-dashboard-pro-react";
import { fetchProgramNextPage } from "../../../../reduxToolkit/features/program/ProgramsSlice";
import { synchMarksWithLMS } from "reduxToolkit/features/LMSIntegration/LMSIntegrationSlice";
import ErrorModal from "../../../Components/ErrorModal";
import Pagination from "@mui/material/Pagination";
import Button from "components/CustomButtons/Button.js";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { notification } from "antd";

const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);
const openNotificationWithIcon = (type, msg) => {
  notification[type]({
    message: type,
    description: msg,
    top: 50,
    duration: 2,
  });
};
const useBackdropStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    fontSize: 14,
    fontWeight: "bold",
    backgroundColor: "rgb(0,0,0,0.7)",
    display: "flex",
    flexDirection: "column",
  },
}));
const ProgramExcerpt = ({
  program,
  id,
  staffList,
  semester_id,
  fetchProgAss,
  page,
  rowsPerPage,
}) => {
  const { t } = useTranslation();
  const cardClasses = useCardStyles();

  return (
    <GridItem xs={12} sm={12} md={6} lg={4} xl={3}>
      <Card style={{ minHeight: "400px", maxHeight: "400px" }} cardHover>
        <CardHeader>
          <GridContainer
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <GridItem xs={10} sm={10} md={10}>
              <Link
                to={`/citrine/program/${program.id}`}
                state={{ semesterID: 10 }}
              >
                <h4 className={cardClasses.cardProductTitle}>
                  {program && program.program_degree_name}
                </h4>
              </Link>
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
              <DropDownButtonAnnualProgram_PES_SES
                ProgramID={program.id}
                data={program}
                staffList={staffList}
                semester_id={semester_id}
                page={page}
                records={rowsPerPage}
                fetchProgAss={fetchProgAss}
              />
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody smallCardBody>
          <GridContainer>
            <GridItem xs={3} sm={3} md={4}>
              <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                {t("Code")}:
              </div>
            </GridItem>
            <GridItem xs={9} sm={9} md={8}>
              <div style={{ color: `${grayColor[1]}`, fontWeight: "normal" }}>
                {program?.code}{" "}
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={3} sm={3} md={4}>
              <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                {t("Category")}:
              </div>
            </GridItem>
            <GridItem xs={9} sm={9} md={8}>
              <div style={{ color: `${grayColor[1]}`, fontWeight: "normal" }}>
                {program?.category_of_the_program_details[0]?.name}
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={3} sm={3} md={4}>
              <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                {t("Total levels")}:
              </div>
            </GridItem>
            <GridItem xs={9} sm={9} md={8}>
              <div style={{ color: `${grayColor[1]}`, fontWeight: "normal" }}>
                {program?.total_level_details[0]?.total_levels}
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={3} sm={3} md={4}>
              <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                {t("University")}:
              </div>
            </GridItem>
            <GridItem xs={9} sm={9} md={8}>
              <div style={{ color: `${grayColor[1]}`, fontWeight: "normal" }}>
                {program?.university_detail[0]?.name}
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={3} sm={3} md={4}>
              <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                {t("Colleges")}:
              </div>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <div style={{ color: `${grayColor[1]}`, fontWeight: "normal" }}>
                {program.college_detail &&
                  program?.college_detail?.map((college, key) => {
                    return (
                      <div style={{ fontWeight: "normal" }} key={key}>
                        {" "}
                        {college?.name}{" "}
                      </div>
                    );
                  })}
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={3} sm={3} md={4}>
              <div style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}>
                {t("Departments")}:
              </div>
            </GridItem>
            <GridItem xs={9} sm={9} md={8}>
              <div
                style={{
                  color: `${grayColor[1]}`,
                  display: "flex",
                  gap: "3px",
                }}
              >
                {program?.department_detail?.map((department, key) => {
                  return (
                    <div key={key}>
                      <span>{department?.name}</span>
                    </div>
                  );
                })}
              </div>
            </GridItem>
          </GridContainer>
          {program && program?.coordinator_id && (
            <GridContainer>
              <GridItem xs={3} sm={3} md={4}>
                <div
                  style={{ color: `${primaryColor[0]}`, fontWeight: "bold" }}
                >
                  {t(" Coordinator")}:
                </div>
              </GridItem>
              <GridItem xs={9} sm={9} md={8}>
                <div style={{ color: `${grayColor[1]}` }}>
                  <div>
                    {program &&
                      program?.coordinator_details?.map((itemName) => {
                        return (
                          <>
                            {itemName?.first_name} {itemName?.last_name}
                          </>
                        );
                      })}
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          )}
        </CardBody>
        <CardFooter>
          <GridContainer justifyContent="space-between">
            <GridItem>
              <span
                style={{
                  color: grayColor[1],
                  fontWeight: "bold",
                  marginLeft: 5,
                  marginBottom: 5,
                }}
              >
                V {program && program.version}
              </span>
            </GridItem>
            <GridItem>
              <span
                style={{
                  backgroundColor:
                    program.state === "Approved"
                      ? successColor[7]
                      : grayColor[4],
                  padding: 5,
                  marginRight: 5,
                  marginBottom: 5,
                  borderRadius: "10px",
                  color: grayColor[1],
                }}
              >
                {program && program.state}
              </span>
            </GridItem>
          </GridContainer>
        </CardFooter>
      </Card>
    </GridItem>
  );
};

export default function ProgramsAssessmentPage() {
  const { t } = useTranslation();
  const titleClasses = useTitleStyles();
  const backdropClasses = useBackdropStyles();

  const staffList = useSelector((state) => state.user.staffList);

  const dispatch = useDispatch();
  const [searchFiltred, setSearchFiltred] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");
  const programsStatus = useSelector((state) => state.program.programsStatus);
  const programsByPage = useSelector((state) => state.program.programsByPage);

  const semester_id = localStorage.getItem("semester_id");
  const data = {
    page: page,
    records: rowsPerPage,
    semester_id: semester_id,
  };

  useEffect(() => {
    dispatch(fetchProgramsByPage(data));
  }, [semester_id]);

  useEffect(() => {
    dispatch(fetchStaff());
  }, []);

  useEffect(() => {
    let noPermession = programsByPage?.results
      ?.toString()
      .includes("do not have permission");
    if (noPermession) {
      Modal.warning({
        title: "Permission denied",
        content: "You do not have permission !! ",
      });
    }
    return () => {
      noPermession = false;
    };
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(fetchProgramNextPage(newPage));
  };

  const fetchProgramAss = () => {
    let data = {
      page: page,
      records: rowsPerPage,
      semester_id: semester_id,
    };
    dispatch(fetchProgramsByPage(data));
  };

  const filteredContent = (
    <>
      <GridContainer>
        {programsByPage?.results
          ?.filter((item) => {
            return item?.program_degree_name
              .toLowerCase()
              ?.includes(searchFiltred.toLowerCase());
          })
          ?.map((program) => (
            <ProgramExcerpt
              key={program.id}
              id={program.id}
              program={program}
              staffList={staffList}
              semester_id={semester_id}
              page={page}
              records={rowsPerPage}
              fetchProgAss={fetchProgramAss}
            />
          ))}
      </GridContainer>
      <div style={{ padding: 10 }}>
        <GridContainer justifyContent="flex-end">
          <GridItem>
            <Pagination
              color={"secondary"}
              count={Math.ceil(programsByPage?.count / rowsPerPage)}
              variant="outlined"
              page={page}
              onChange={handleChangePage}
            />
          </GridItem>
        </GridContainer>
      </div>
    </>
  );

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const previousPage = () => {
    if (programsByPage.previous !== null) {
      dispatch(fetchProgramNextPage(programsByPage.previous));
      setPage(page - 1);
    }
  };

  const nextPage = () => {
    if (programsByPage.next !== null) {
      dispatch(fetchProgramNextPage(programsByPage.next));
      setPage(page + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
    let data = {
      page: page,
      records: event.target.value,
      semester_id: semester_id,
    };
    dispatch(fetchProgramsByPage(data));
  };

  let content;

  if (programsStatus === "loading") {
    content = <div className="loader">{t("Loading...")}</div>;
  } else if (programsStatus === "succeeded") {
    content = filteredContent;
  }

  const synchMarksWithLMSHandler = async () => {
    try {
      const resultAction = await dispatch(synchMarksWithLMS()).unwrap();
      // handle result here
      if (resultAction === "succeeded") {
        openNotificationWithIcon(
          "success",
          `Synchronized assessments' data successfully`
        );
      } else {
        openNotificationWithIcon(
          "error",
          `Assessments' data synchronization failed`
        );
      }
    } catch (rejectedValueOrSerializedError) {
      // handle error here
      console.log("error in component", rejectedValueOrSerializedError);
      setHandledError(rejectedValueOrSerializedError);
      handleOpenErrorModal();
    }
  };

  const { loading } = useSelector((state) => state.LMSIntegration);

  return (
    <>
      <GridContainer
        alignItems="center"
        style={{
          borderTop: `1px solid  ${grayColor[15]}`,
          minHeight: "50px",
          maxHeight: "50px",
        }}
      >
        <GridItem>
          <Link to={`/citrine/programs_assessment`}>
            <span className={titleClasses.title}>
              {" "}
              {t("Programs Assessment")}{" "}
            </span>
          </Link>
        </GridItem>
      </GridContainer>

      <GridContainer alignItems="center" justifyContent="flex-end">
        <GridItem>
          <input
            placeholder="Search"
            style={{
              backgroundColor: "#eeeeee",
              border: "none",
              borderBottom: "1px solid #BDBDBD",
              marginRight: "50px",
              width: "250px",
              height: "30px",
            }}
            type="text"
            onChange={(e) => setSearchFiltred(e.target.value)}
            value={searchFiltred}
          />
        </GridItem>
        <GridItem>
          <Button color="primary" size="md" onClick={synchMarksWithLMSHandler}>
            <span style={{ color: "white" }}>{t("Synch data with LMS")}</span>
          </Button>
        </GridItem>
      </GridContainer>

      <GridContainer>{content}</GridContainer>
      {/*<GridContainer>
        <Card>
          <CardBody>
            <TablePagination
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} OF ${count !== -1 ? count : `MORE THAN ${to}`}`
              }
              component="div"
              count={programsByPage?.count}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              page={page - 1}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              backIconButtonProps={{
                "aria-label": "previous page",
                onClick: previousPage,
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
                onClick: nextPage,
              }}
            />
          </CardBody>
        </Card>
      </GridContainer>*/}
      <Backdrop
        className={backdropClasses.backdrop}
        open={loading === "pending"}
      >
        <CircularProgress color="inherit" />
        <br />
        <br />

        <p>
          Synchronizing assessments' data from LMS, this may take a few
          minutes...
        </p>
      </Backdrop>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </>
  );
}
