import { makeStyles } from '@material-ui/core/styles'
import navBarStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js'
import titleStyles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'
import cardStyles from 'assets/jss/material-dashboard-pro-react/components/cardStyle.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js'
import Card from 'components/Card/Card.js'
import CardFooter from 'components/Card/CardFooter'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
// import pagesHeaderstyle from "assets/jss/material-dashboard-pro-react/components/pagesHeaderStyle";
import { grayColor } from '../../../../../assets/jss/material-dashboard-pro-react'
import { fetchCategories } from '../../../../../reduxToolkit/features/category/CategorySlice'
import DropDownCategoryButton from '../../../../Components/DropDownCategoryButton'
import { Badge, Breadcrumb } from "antd";

const useStyles = makeStyles(styles)
const useNavBarStyles = makeStyles(navBarStyles)
const useTitleStyles = makeStyles(titleStyles)
const useCardStyles = makeStyles(cardStyles)
// const usePagesHeaderstyle = makeStyles(pagesHeaderstyle);

const CategoryExcerpt = ({ category }) => {
  console.log(category.fieldSpec_details)
  const { t } = useTranslation()
  const classes = useStyles()
  const cardClasses = useCardStyles()

  return (
    <GridItem xs={12} sm={12} md={4} key={category.id}>
      <Card
        style={{
          maxWidth: '380px',
          minWidth: '380px',
          maxHeight: '180px',
          minHeight: '180px',
        }}
        cardHover
      >
        <CardHeader>
          <GridContainer
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <GridItem xs={10} sm={10} md={10}>
              {/* <Link to={`/citrine/programSpec/${program.id}`}> */}
              <h4 className={cardClasses.cardProductTitle}>{category.name}</h4>
              {/* </Link> */}
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
              <DropDownCategoryButton id={category.id} />
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody smallCardBody>
          {category &&
          category.fieldSpec_details &&
          category.fieldSpec_details.length ? (
            category.fieldSpec_details.map((el) => (
              <Badge
                className="site-badge-count-109"
                count={el.name}
                style={{ backgroundColor: '#52c41a' }}
              />
            ))
          ) : (
            <span></span>
          )}
        </CardBody>
        <CardFooter>
          <GridContainer direction="row" justify="flex-end" alignItems="center">
            {/* <GridItem> <span style={{ color: (program.state) === "Draft" ? grayColor[1] : "#FFCB3B" }}>{program.state}</span> </GridItem> */}
          </GridContainer>
        </CardFooter>
      </Card>
    </GridItem>
  )
}

export default function Categories() {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')

  const classes = useStyles()
  const navBarClasses = useNavBarStyles()
  const titleClasses = useTitleStyles()
  // const headerClasses = usePagesHeaderstyle();
  // console.log('token', token)

  const dispatch = useDispatch()

  const categoriesStatus = useSelector(
    (state) => state.category.categoriesStatus,
  )
  useEffect(() => {
    if (categoriesStatus === 'idle') {
      dispatch(fetchCategories())
    }
  }, [categoriesStatus, dispatch])

  const categories = useSelector((state) => state.category.categories)
  const error = useSelector((state) => state.category.error)
  console.log(categories, 'categories')
  let content

  const searchButton = navBarClasses.top + ' ' + navBarClasses.searchButton

  if (categoriesStatus === 'loading') {
    content = <div className="loader">Loading...</div>
  } else if (categoriesStatus === 'succeeded') {
    content =
      categories &&
      categories
        .filter((category) => {
          if (search == '') {
            return category
          } else if (
            category.name.toLowerCase().includes(search.toLowerCase())
          ) {
            return category
          }
        })
        .map((category) => (
          <CategoryExcerpt key={category.id} category={category} />
        ))
  } else if (categoriesStatus === 'failed') {
    content = <div>{error}</div>
  }
  return (
    <div>
      <GridContainer
        alignItems="center"
        style={{
          borderTop: `1px solid  ${grayColor[15]}`,
          minHeight: '50px',
          maxHeight: '50px',
        }}
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>{t('Configurations')}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/configuration/program`}>
                <span> {t('Program Configurations')} </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/citrine/configuration/program/category`}
                style={{ color: 'orange' }}
              >
                {t('Categories')}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>

      <GridContainer justify="space-between" alignItems="center">
        <GridItem>
          <Link to="/citrine/configuration/program/category/createCategory/:id">
            <Button color="primary">{t(' Add')}</Button>
          </Link>
        </GridItem>
      </GridContainer>

      <GridContainer>{content}</GridContainer>
    </div>
  )
}
