// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import { unwrapResult } from "@reduxjs/toolkit";
import { Breadcrumb, Select } from "antd";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchCategories } from "reduxToolkit/features/category/CategorySlice";
import {
  addNewCategory, fetchCategoryById, updateCategory
} from "../../../../../reduxToolkit/features/category/CategorySlice";
import { fetchFields } from "../../../../../reduxToolkit/features/field/FieldSpecSlice";







export default function CreateCategory({ match }) {
  const { t } = useTranslation();
  const { id } = match.params;

  console.log("id", id);

  const isAddMode = isNaN(id);
  console.log("id from match is add mode", isAddMode);
  const [name, setName] = useState("");
  const [fieldSpec_ids, setFieldSpec_ids] = useState([]);

  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const dispatch = useDispatch();

  const fieldsStatus = useSelector((state) => state.fieldSpec.fieldsStatus);

  useEffect(() => {
    if (fieldsStatus === "idle") {
      dispatch(fetchFields());
    }
  }, [fieldsStatus, dispatch]);

  const fields = useSelector((state) => state.fieldSpec.fields);

  const { Option } = Select;

  function handleField_ids(value) {
    //  console.log(`selected ${value}`);
    setFieldSpec_ids(value);
  }

  const onSaveCategoryClicked = async () => {
    try {
      setAddRequestStatus("pending");
      const resultAction = await dispatch(
        addNewCategory({ name, fieldSpec_ids })
      );
      unwrapResult(resultAction);
      dispatch(fetchCategories());
    } catch (err) {
      console.error("Failed to save the category: ", err);
    } finally {
      setAddRequestStatus("idle");
    }
  };
  const onUpdateCategoryClicked = async () => {
    try {
      setAddRequestStatus("pending");
      const resultAction = await dispatch(
        updateCategory({ id: id, modified: { name, fieldSpec_ids } })
      );
      unwrapResult(resultAction);
      dispatch(fetchCategories());
    } catch (err) {
      console.error("Failed to save the category: ", err);
    } finally {
      setAddRequestStatus("idle");
    }
  };

  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchCategoryById(id));
    }
  }, [id]);

  const category = useSelector((state) => state.category.category);
  console.log("category ", category);

  useEffect(() => {
    if (!isAddMode) {
      setName(category && category.name);
      setFieldSpec_ids(category && category.fieldSpec_ids);
    }
  }, [category, dispatch]);
  return (
    <div>
      {isAddMode && (
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <Breadcrumb separator=">">
                  <Breadcrumb.Item>{t("Configurations")}</Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={`/citrine/configuration/program`}>
                      <span> {t("Program Configurations")} </span>
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link
                      to={`/citrine/configuration/program/category`}
                      style={{ color: "orange" }}
                    >
                      {t("Categories")}
                    </Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Card>
                  <CardHeader color="primary" icon>
                    <CardIcon color="primary">
                      <MailOutline />
                    </CardIcon>
                    <h4>{t("Add Category")}</h4>
                  </CardHeader>
                  <CardBody>
                    <form>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                            }}
                            id="name"
                            labelText={t("Name")}
                            inputProps={{
                              value: name,
                              onChange: (event) => {
                                setName(event.target.value);
                              },
                              type: "text",
                            }}
                          />

                          <GridItem>
                            <label>{t("Field")}</label>

                            <Select
                              mode="multiple"
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="Select a field"
                              optionFilterProp="children"
                              // value={fieldSpec_ids}
                              onChange={handleField_ids}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {fields &&
                                fields.map((field) => (
                                  <Option key={field.id} value={field.id}>
                                    {field.name}
                                  </Option>
                                ))}
                            </Select>
                          </GridItem>

                          <br />
                          <br />
                          <Link to={`/citrine/configuration/program/category`}>
                            <Button
                              color="primary"
                              onClick={onSaveCategoryClicked}
                            >
                              {t(" Save")}
                            </Button>
                          </Link>
                        </GridItem>
                      </GridContainer>
                    </form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      )}
      {!isAddMode && category && (
        <div>
          <div>
            <GridContainer>
              <GridItem>
                <Breadcrumb separator=">">
                  <Breadcrumb.Item>{t("Configurations")}</Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to={`/citrine/configuration/program`}>
                      <span> {t("Program Configurations")} </span>
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link
                      to={`/citrine/configuration/program/category`}
                      style={{ color: "orange" }}
                    >
                      {t("Categories")}
                    </Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Card>
                  <CardHeader color="primary" icon>
                    <CardIcon color="primary">
                      <MailOutline />
                    </CardIcon>
                    <h4>{t("Edit Category")}</h4>
                  </CardHeader>
                  <CardBody>
                    <form>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                            }}
                            id="name"
                            labelText={t("Name")}
                            inputProps={{
                              value: name,
                              onChange: (event) => {
                                setName(event.target.value);
                              },
                              type: "text",
                            }}
                          />

                          <GridItem>
                            <label>{t("Field")}</label>

                            <Select
                              mode="multiple"
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="Select a field"
                              optionFilterProp="children"
                              value={fieldSpec_ids}
                              onChange={handleField_ids}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {fields &&
                                fields.map((field) => (
                                  <Option key={field.id} value={field.id}>
                                    {field.name}
                                  </Option>
                                ))}
                            </Select>
                          </GridItem>

                          <br />
                          <br />
                          <Link to={`/citrine/configuration/program/category`}>
                            <Button
                              color="primary"
                              onClick={onUpdateCategoryClicked}
                            >
                              {t("Update")}
                            </Button>
                          </Link>
                        </GridItem>
                      </GridContainer>
                    </form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      )}
    </div>
  );
}
