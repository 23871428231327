import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
// antdesign components
import { Form, Input, message, notification, Select } from "antd";
import { grayColor, primaryColor } from "assets/jss/material-dashboard-pro-react";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
// style for this view
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { fetchAdmissionRequirements } from "reduxToolkit/features/admissionRequirement/AdmissionRequirementSpecSlice";
import { fetchBranches } from "reduxToolkit/features/branche/BranchesSlice";
import { fetchGraduationRequirements } from "reduxToolkit/features/graduationRequirement/GraduationRequirementSpecSlice";
import {
  addNewKeyWords,
  fetchKeyWords
} from "reduxToolkit/features/keywords/KeywordSpecSlice";
import { infoColor } from "../../../../assets/jss/material-dashboard-pro-react";
import {
  fetchCategories
} from "../../../../reduxToolkit/features/category/CategorySlice";
import {
  fetchFieldById,
  fetchFields
} from "../../../../reduxToolkit/features/field/FieldSpecSlice";
import { EditProgram, fetchProgramById } from "../../../../reduxToolkit/features/program/ProgramSpecSlice";
import {
  fetchTotalLevels
} from "../../../../reduxToolkit/features/totalLevel/TotalLevelSlice";
import { fetchUniversities, fetchUniversityById } from "../../../../reduxToolkit/features/university/UniversitiesSlice";
import ErrorModal from "../../../Components/ErrorModal";
const useTitleStyles = makeStyles(titleStyles);

export default function UpdateProgramForm({ match }) {
  const { t } = useTranslation();
  let history = useHistory();
  const { id } = match.params;
  const titleClasses = useTitleStyles();
  const [form] = Form.useForm();
  const { TextArea } = Input;


  const [fieldSpec_id, setFieldSpec_id] = React.useState(null);
  const [university_id, setUniversity_id] = React.useState(null);
  //const [keyWordsSpec_ids, setKeyWordsSpec_ids] = useState([]);
  const [coordinator_id, setCoordinator_id] = useState(null);
  const [interims_ids, setInterims_ids] = useState(null);
  const [courseSpec_ids, setCourseSpec_ids] = useState(null);


  const dispatch = useDispatch();


  const fieldsStatus = useSelector((state) => state.fieldSpec.fieldsStatus);
  const admissionRequirementsStatus = useSelector(
    (state) => state.admissionRequirementSpec.admissionRequirementsStatus
  );
  const graduationRequirementsStatus = useSelector(
    (state) => state.graduationRequirementSpec.graduationRequirementsStatus
  );
  const universitiesStatus = useSelector(
    (state) => state.university.universitiesStatus
  );

  const branchesStatus = useSelector((state) => state.branches.branchesStatus);
  const keyWordsStatus = useSelector((state) => state.keyWordSpec.keyWordsStatus)
  const [filteredCategories, setFilteredCategories] = useState(null);
  const [filteredTotalLevels, setFilteredTotalLevels] = useState(null);


  useEffect(() => {
    if (universitiesStatus === "idle") {
      dispatch(fetchUniversities());
    }
    if (branchesStatus === "idle") {
      dispatch(fetchBranches());
    }
    if (fieldsStatus === "idle") {
      dispatch(fetchFields());
    }
    if (admissionRequirementsStatus === "idle") {
      dispatch(fetchAdmissionRequirements());
    }
    if (graduationRequirementsStatus === "idle") {
      dispatch(fetchGraduationRequirements());
    }
    dispatch(fetchCategories())
      .unwrap()
      .then((originalPromiseResult) => {
        // handle result here
        setFilteredCategories(originalPromiseResult)
      })
    dispatch(fetchTotalLevels())
      .unwrap()
      .then((originalPromiseResult) => {
        // handle result here
        setFilteredTotalLevels(originalPromiseResult)
      })
    if (keyWordsStatus === "idle") {
      dispatch(fetchKeyWords());
    }

  }, [
    fieldsStatus,
    universitiesStatus,
    admissionRequirementsStatus,
    graduationRequirementsStatus,
    dispatch,
  ]);

  const universities = useSelector((state) => state.university.universities);

  const branches = useSelector((state) => state.branches.branches);

  function onChangeUniversity(value) {
    //  console.log(`selected ${value}`);
    setUniversity_id(value);
    dispatch(fetchUniversityById(value));
  }


  const university = useSelector((state) => state.university.university);

  console.log("selected university", university);

  useEffect(() => {
    dispatch(fetchProgramById(id))
      .then(unwrapResult)
      .then((program) => {
        // do additional work
        console.log("originalPromiseResult", program);
        if (program.specialization_spec === 0) {
          form.setFieldsValue({
            programSpec_id: program.id,
            program_degree_name: program.program_degree_name,
            accredited_campus_locations: program.accredited_campus_locations,
            criteria: program.criteria,
            reasons: program.reasons,
            mission: program.mission,
            goals: program.goals,
            fieldSpec_id: program.fieldSpec_id,

            specialization_spec: null,
            admissionRequirementSpec_ids: program.admissionRequirementSpec_ids,
            graduationRequirementSpec_ids: program.graduationRequirementSpec_ids,
            code: program.code,
            keyWordsSpec_ids: program.keyWordsSpec_ids,

            total_level_id: program.total_level_id,
            category_of_the_program_id: program.category_of_the_program_id,
            assessment_calculation_method: program.assessment_calculation_method,
            university_id: program.university_id,
            college_ids: program.college_ids,
            department_ids: program.department_ids,
            branches_ids: program.branches_ids,
            guidance_for_new_students: program.guidance_for_new_students,
            student_counseling: program.student_counseling,
            special_support: program.special_support,

          });
        }
        else {
          form.setFieldsValue({
            programSpec_id: program.id,
            program_degree_name: program.program_degree_name,
            accredited_campus_locations: program.accredited_campus_locations,
            criteria: program.criteria,
            reasons: program.reasons,
            mission: program.mission,
            goals: program.goals,
            fieldSpec_id: program.fieldSpec_id,

            specialization_spec: program.specialization_spec,
            admissionRequirementSpec_ids: program.admissionRequirementSpec_ids,
            graduationRequirementSpec_ids: program.graduationRequirementSpec_ids,
            code: program.code,
            keyWordsSpec_ids: program.keyWordsSpec_ids,
            total_level_id: program.total_level_id,
            category_of_the_program_id: program.category_of_the_program_id,
            assessment_calculation_method: program.assessment_calculation_method,
            university_id: program.university_id,
            college_ids: program.college_ids,
            department_ids: program.department_ids,
            branches_ids: program.branches_ids,
            guidance_for_new_students: program.guidance_for_new_students,
            student_counseling: program.student_counseling,
            special_support: program.special_support,

          });
        }
        setCoordinator_id(program.coordinator_id);
        setInterims_ids(program.interims_ids);
        setCourseSpec_ids(program.courseSpec_ids);
        dispatch(fetchFieldById(program.fieldSpec_id));
        // dispatch(fetchSpecializationById(program.specialization_spec));
        // dispatch(fetchTotalLevelById(program.total_level_id))
        // .then(unwrapResult)
        // .then((totalLevel) => {
        //   // do additional work
        //   setTotalLevels_id(totalLevel.total_levels);
        // });
        // dispatch(fetchCategoryById(program.category_of_the_program_id))
        // .then(unwrapResult)
        // .then((category) => {
        //   // do additional work
        //   setCategory_of_the_program_id(category.name);
        // });
        dispatch(fetchUniversityById(program.university_id));
      });
  }, [dispatch]);

  const program = useSelector((state) => state.programSpec.program);
  console.log(program)


  const fields = useSelector((state) => state.fieldSpec.fields);

  const categories = useSelector((state) => state.category.categories);
  const totalLevels = useSelector((state) => state.totalLevel.totalLevels);

  const admissionRequirements = useSelector(
    (state) => state.admissionRequirementSpec.admissionRequirements
  );
  const graduationRequirements = useSelector(
    (state) => state.graduationRequirementSpec.graduationRequirements
  );

  const keyWords = useSelector((state) => state.keyWordSpec.keyWords);


  const handleField_id = (value) => {
    console.log(`selected field in onchange ${value}`);
    setFieldSpec_id(value);
    dispatch(fetchFieldById(value))
      .then(unwrapResult)
      .then((field) => {
        // do additional work;
        console.log("fetched field", field);
        console.log("categories", categories);
        console.log("totalLevels", totalLevels);

        setFilteredCategories(
          categories &&
          categories.filter((category) =>
            category.fieldSpec_ids.includes(field.id)
          )
        );
        setFilteredTotalLevels(
          totalLevels &&
          totalLevels.filter((totalLevel) =>
            totalLevel.fieldSpec_ids.includes(field.id)
          )
        );
      });
  };
  const newField = useSelector((state) => state.fieldSpec.field);



  // const handleChange = (value) => {
  //   if (value.length > 0) {
  //     // handle result here
  //     let chosenKWObjects =
  //       keyWords && keyWords.filter((kw) => value.includes(kw.key_words));
  //     console.log("chosenKWObjects", chosenKWObjects);
  //     let ids = chosenKWObjects.map((el) => {
  //       return el.id;
  //     });

  //     let mapped =
  //       keyWords &&
  //       keyWords.map((el) => {
  //         return el.key_words;
  //       });

  //     const key_words = [...value].pop();
  //     if (mapped.includes(key_words)) {
  //       setKeyWordsSpec_ids(ids);
  //     } else {
  //       dispatch(addNewKeyWords({ key_words }))
  //         .then(unwrapResult)
  //         .then((newKeyWord) => {
  //           console.log("added key word", newKeyWord);
  //           setKeyWordsSpec_ids(ids.concat(newKeyWord.id));
  //         });
  //     }
  //   } else {
  //     setKeyWordsSpec_ids([]);
  //   }
  // };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.error("Submit failed!");
  };

  const onFinish = async (values) => {
    console.log("Received values of form: ", values.keyWordsSpec_ids);

    // const updatedProgram = {
    //   ...values,
    //   keyWordsSpec_ids: keyWordsSpec_ids,
    //   coordinator_id: coordinator_id,
    //   interims_ids: interims_ids,
    //   courseSpec_ids: courseSpec_ids,
    // };
    //console.log("updatedProgram: ", updatedProgram);

    const resultAction = await dispatch(
      EditProgram({
        ...values,
        programSpec_id: id,
        keyWordsSpec_ids: values.keyWordsSpec_ids,
        coordinator_id: coordinator_id,
        interims_ids: interims_ids,
        courseSpec_ids: courseSpec_ids,
      })
    );

    if (EditProgram.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      const progarm = resultAction.payload;
      console.log("success", `Updated ${progarm.program_degree_name}`);
      openNotificationWithIcon(
        "success",
        `Updated ${progarm.program_degree_name}`
      );
      history.push("/citrine/programSpec");
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
        console.log(
          "resultAction.payload from component",
          resultAction.payload
        );
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        console.log("resultAction.error  from component", resultAction.error);
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };

  const { Option } = Select;

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
      >
        <GridItem>
          <div style={{ height: "50px" }}>
            <Link to={`/citrine/programSpec`}>
              <span className={titleClasses.title}> {t("Programs")} </span>
            </Link>
            <Link to={`/citrine/programSpec/${program && program.id}`}>
              <span className={titleClasses.title}>
                {" "}
                {`> ${program && program.program_degree_name}`}{" "}
              </span>
            </Link>
            <Link>
              <span className={titleClasses.title}>{t("> Update")} </span>
            </Link>
          </div>
        </GridItem>
      </GridContainer>


      <Form
        name="basic"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError
      >
        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={10}>
            <h3>
              <small style={{ color: `${infoColor[0]}` }}>
                Program General Information
              </small>
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={8} sm={8} md={8}>
                    <Form.Item
                      label={t("Name")}
                      name="program_degree_name"
                      // hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's name",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={4} sm={4} md={4}>
                    <Form.Item
                      label={t("Code")}
                      name="code"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's code",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Field")}
                      name="fieldSpec_id"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's field",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        name="fieldSpec_id"
                        value={fieldSpec_id}
                        onChange={handleField_id}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {fields &&
                          fields.map((field) => (
                            <Option key={field.id} value={field.id}>
                              {field.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Specialization")}
                      name="specialization_spec"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's specialization",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        name="specialization_spec"
                        // onChange={handleSpecialization_id}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: "100%" }}
                      >
                        {newField &&
                          newField.specializationsSpec &&
                          newField.specializationsSpec.map((specialization) => (
                            <Option
                              key={specialization.id}
                              value={specialization.id}
                            >
                              {/* {specialization.name !== 0 && (<div> */}
                              {specialization.name}
                              {/* </div>)} */}
                              {/* {specialization.name} */}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Category")}
                      name="category_of_the_program_id"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's category",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        name="category_of_the_program_id"
                        // onChange={handleCategory}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {filteredCategories &&
                          filteredCategories.map((category) => (
                            <Option key={category.id} value={category.id}>
                              {category.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Total levels")}
                      name="total_level_id"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's total levels",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        name="total_level_id"
                      // onChange={handleTotalLevels}

                      >
                        {filteredTotalLevels &&
                          filteredTotalLevels.map((totalLevel) => (
                            <Option key={totalLevel.id} value={totalLevel.id}>
                              {totalLevel.total_levels}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Admission requirements")}
                      name="admissionRequirementSpec_ids"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please input the program's admission requirements",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        size={200}
                        showArrow
                      // onChange={handleAdmissionRequirements_ids}
                      >
                        {admissionRequirements && admissionRequirements.map((req) => (
                          <Option key={req.id} value={req.id}>
                            {req.title}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Graduation requirements")}
                      name="graduationRequirementSpec_ids"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please input the program's graduation requirements",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        size={200}
                        showArrow
                      // onChange={handleGraduationRequirements_ids}
                      >
                        {graduationRequirements && graduationRequirements.map((req) => (
                          <Option key={req.id} value={req.id}>
                            {req.title}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Keywords")}
                      name="keyWordsSpec_ids"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's keyWords",
                        },
                      ]}
                    >
                      <Select
                        mode="tags"
                        style={{ width: "100%" }}
                      //onChange={handleChange}
                      >
                        {keyWords &&
                          keyWords.map((keyWord) => (
                            <Option key={keyWord.id} value={keyWord.id}>
                              {keyWord.key_words}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  {/* <GridItem xs={6} sm={6} md={6}>
                    <Form.Item
                      label={t("Assessment calculation method")}
                      name="assessment_calculation_method"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please input the assessment calculation method",
                        },
                      ]}
                    >
                      <Select
                        style={{ width: "100%" }}
                      >
                        <Option value="clo"> CLOs </Option>
                        <Option value="question"> Questions </Option>
                      </Select>
                    </Form.Item>
                  </GridItem> */}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={10}>
            <h3>
              <small style={{ color: `${infoColor[0]}` }}>
                Criteria, Mission & Goals
              </small>
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Criteria")}
                      name="criteria"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's criteria",
                        },
                      ]}
                    >
                      <Input.TextArea />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Reasons for Establishing the Program")}
                      name="reasons"
                      rules={[{ required: true }]}
                    >
                      <TextArea rows={4} />

                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Mission")}
                      name="mission"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's mission",
                        },
                      ]}
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Goals")}
                      name="goals"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's goals",
                        },
                      ]}
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={10}>
            <h3>
              <small style={{ color: `${primaryColor[0]}` }}>
                Institutional Information
              </small>
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={4} sm={4} md={6}>
                    <Form.Item
                      label={t("University")}
                      name="university_id"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's university",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        name="university_id"
                        value={university_id}
                        onChange={onChangeUniversity}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {universities.map((university) => (
                          <Option key={university.id} value={university.id}>
                            {university.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={4} sm={4} md={6}>
                    <Form.Item
                      label={t("Colleges")}
                      name="college_ids"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's colleges",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        showArrow
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {university &&
                          university.colleges &&
                          university.colleges.map((college) => (
                            <Option key={college.id} value={college.id}>
                              {college.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={4} sm={4} md={6}>
                    <Form.Item label={t("Departments")} name="department_ids">
                      <Select
                        mode="multiple"
                        showSearch
                        showArrow
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {university &&
                          university.colleges &&
                          university.colleges.map(
                            (college) =>
                              college &&
                              college.departments &&
                              college.departments.map((department) => (
                                <Option
                                  key={department.id}
                                  value={department.id}
                                >
                                  {department.name}
                                </Option>
                              ))
                          )}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={4} sm={4} md={6}>
                    <Form.Item
                      label={t("Branches Offering the Program")}
                      name="branches_ids"
                      rules={[
                        {
                          required: true,
                          message: "Please input the program's branches",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        showArrow
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {branches &&
                          branches.map((branche) => (
                            <Option key={branche.id} value={branche.id}>
                              {branche.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Campus locations")}
                      name="accredited_campus_locations"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please input the program's accredited_campus_locations",
                        },
                      ]}
                    >
                      <Input.TextArea />
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>

          {/* <GridItem xs={12} sm={12} md={10}>
                        <h3>
                            <small style={{ color: `${primaryColor[0]}` }}>
                                Accreditation Information
                            </small>
                        </h3>
                    </GridItem> */}
          {/* <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={4} sm={4} md={4}>
                                        <Form.Item
                                            label={t('Accreditation dates')}
                                            name="accreditation_dates"
                                        >
                                            <DatePicker  onChange={onAccreditationDatesChange} />
                                        </Form.Item>
                                    </GridItem>

                                    <GridItem xs={4} sm={4} md={4}>
                                        <Form.Item
                                            label={t('Date of next comprehensive review')}
                                            name="date_of_next_comprehensive_review"
                                        >
                                            <DatePicker  onChange={onDateOfNextComprehensiveReviewChange} />
                                        </Form.Item>
                                    </GridItem>

                                    <GridItem xs={4} sm={4} md={4}>
                                        <Form.Item
                                            label={t("Accredited by")}
                                            name="accredited_by"
                                        >
                                            <Input
                                                id="accredited_by"
                                                value={accredited_by}
                                                onChange={event => {
                                                    setAccredited_by(event.target.value);
                                                }}
                                            />
                                        </Form.Item>
                                    </GridItem>
                                </GridContainer>
                            </CardBody >
                        </Card >
                    </GridItem> */}
          <GridItem xs={12} sm={12} md={10}>
            <h3>
              <small style={{ color: `${primaryColor[0]}` }}>
                Student Support
              </small>
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t(
                        "Guidance and Orientation Programs for New Students "
                      )}
                      name="guidance_for_new_students"
                      rules={[{ required: true }]}
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Student Counseling Services ")}
                      name="student_counseling"
                      rules={[{ required: true }]}
                    >
                      <TextArea />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Special Support ")}
                      name="special_support"
                      rules={[{ required: true }]}
                    >
                      <TextArea />
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>

          {/* </FormControl> */}

          {/* <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={10}>
            <GridContainer justify="flex-end">
              <GridItem>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="submit" color="primary">
                    {t("Save")}
                  </Button>
                </Form.Item>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer> */}


          <GridItem xs={12} sm={12} md={10}>
            <Form.Item>
              <GridContainer justify="flex-end">
                <GridItem>
                  <Button color="primary" onClick={() => {
                    history.push("/citrine/programSpec");
                  }}>
                    {t('Cancel')}
                  </Button>
                </GridItem>
                <GridItem>
                  <Button type="submit" color="primary">
                    {t('Save')}
                  </Button>
                </GridItem>
              </GridContainer>
            </Form.Item>
          </GridItem>
        </GridContainer>
      </Form>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
