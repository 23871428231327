import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DatePicker, Form, notification, Select, Input } from "antd";
// import Button from "components/CustomButtons/Button.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ErrorModal from "../../../Components/ErrorModal.jsx";
import {addNewSemester} from "../../../../reduxToolkit/features/semester/SemestersSlice.js"
import { fetchAcademicYearById } from '../../../../reduxToolkit/features/academicYear/AcademicYearsSlice';

export default function CreateSemesterModal({ academicYear_id }) {
    const { t } = useTranslation();
    const { Option } = Select;
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [handledError, setHandledError] = React.useState("");
    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const [start_date, setStart_date] = useState("");
    const [end_date, setEnd_date] = useState("");

    const dispatch = useDispatch();

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        });
    };

    function onStart_dateChange(value, dateString) {
        setStart_date(dateString);
    }
    function onEnd_dateChange(value, dateString) {
        setEnd_date(dateString);
    }

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
        // message.error('Submit failed!');
    };

    const onFinish = async (values) => {
        console.log("values", values);

        let data
        const { start, end, ...valuesRest } = values;
        console.log("valuesRest", valuesRest);

        data = { ...valuesRest, start_date, end_date, academicYear_id }
        console.log("data", data);

        const resultAction = await dispatch(
            addNewSemester(data)
        );

        if (addNewSemester.fulfilled.match(resultAction)) {
            // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
            const semester = resultAction.payload;
            dispatch(fetchAcademicYearById(academicYear_id))
            openNotificationWithIcon("success", `Added ${semester.name}`);
            handleClose();
        } else {
            if (resultAction.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.
                setHandledError(resultAction.payload);
                handleOpenErrorModal();
                handleClose();
            } else {
                console.log("resultAction.error  from component", resultAction.error);
                setHandledError(resultAction.error);
                handleOpenErrorModal();
                handleClose();
            }
        }
    };

    return (
        <div>
            <Button color="primary" onClick={handleClickOpen}>
                {t("Add")}
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    {t("Add a term")}
                </DialogTitle>
                <DialogContent>
                    <GridContainer>
                        <GridItem xs={10} sm={10} md={12}>
                            <Form
                                name="basic"
                                layout="vertical"
                                //   labelCol={{ span: 8 }}
                                //   wrapperCol={{ span: 16 }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                scrollToFirstError
                            >
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Form.Item
                                            label={t("Term")}
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                             <Input />
                                        </Form.Item>
                                    </GridItem>

                                    <GridItem xs={6} sm={6} md={6}>
                                        <Form.Item
                                            label={t("Start date")}
                                            name="start"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'input start date!',
                                                },
                                            ]}
                                        >
                                            <DatePicker onChange={onStart_dateChange} style={{ width: "100%" }} />
                                        </Form.Item>
                                    </GridItem>

                                    <GridItem xs={6} sm={6} md={6}>
                                        <Form.Item
                                            label={t("End date")}
                                            name="end"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'input end date!',
                                                },
                                            ]}
                                        >
                                            <DatePicker onChange={onEnd_dateChange} style={{ width: "100%" }} />
                                        </Form.Item>
                                    </GridItem>
                                </GridContainer>

                                {/* </FormControl> */}
                                <GridContainer justify="flex-end">
                                    <GridItem>
                                        <Form.Item>
                                            <GridContainer>
                                                <GridItem>
                                                    <Button
                                                        color="primary"
                                                        onClick={() => {
                                                            handleClose();
                                                        }}
                                                    >
                                                        {t("Cancel")}
                                                    </Button>
                                                </GridItem>
                                                <GridItem>
                                                    <Button type="submit" color="primary">
                                                        {t("Save")}
                                                    </Button>
                                                </GridItem>
                                            </GridContainer>
                                        </Form.Item>
                                    </GridItem>
                                </GridContainer>
                            </Form>
                        </GridItem>
                    </GridContainer>
                </DialogContent>
            </Dialog>

            <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />
        </div>
    );
}
