import React, { useEffect, useState } from "react";

// @material-ui/core components

import { useDispatch, useSelector } from "react-redux";

// core components
/// test
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import "antd/dist/antd.css";

import { fetchAssessmentQuestionsMethods } from "reduxToolkit/features/assessmentMethod/AssessmentMethodsSlice";
import { EditAssessmentMethod } from "../../reduxToolkit/features/ActivitiesAssessmentMethod/ActivitiesAssessmentMethodSlice";


import { Form, DatePicker, notification, Select } from "antd";
import { useTranslation } from "react-i18next";
import ErrorModal from "./ErrorModal";

import moment from 'moment';
import isDate2GreaterThanDate1 from "assets/utils/helperFunction"
const layout = {
    labelCol: {
        span: 5,
    },
    wrapperCol: {
        span: 18,
    },
};


export default function EditAssessmentDates(props) {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [form] = Form.useForm();


    const assessmentMethod_id = props.assessmentMethod_id
    const course_id = props.course_id

    const [showSaveButton, setShowSaveButton] = useState(false);


    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [handledError, setHandledError] = useState("");

    const [start_date, setStart_date] = useState(null);
    const [startDateDPProps, setStartDateDPProps] = useState();

    const [due_date, setDue_date] = useState(null);
    const [dueDateDPProps, setDueDateDPProps] = useState();
    const [due_date_validation_status, setDue_date_validation_status] = useState("");



    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: type,
            description: msg,
            top: 50,
            duration: 2,
        });
    };

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().startOf('day');
    };

    const handleStartDateChange = (date, dateString) => {
        setStart_date(dateString)
    }

    const handleDueDateChange = (date, dateString) => {
        setDue_date(dateString)
    }

    useEffect(() => {
        if (start_date && due_date) {
            isDate2GreaterThanDate1(start_date, due_date) ? setDue_date_validation_status("") : setDue_date_validation_status('error')
        }
    }, [start_date, due_date])

    useEffect(() => {
        if (props.assessmentQuestionsMethod) {

            if (props.assessmentQuestionsMethod.assessment_start_date) { setStartDateDPProps({ defaultValue: moment(props.assessmentQuestionsMethod.assessment_start_date, 'YYYY-MM-DD') }) } else { setStartDateDPProps({}) }

            if (props.assessmentQuestionsMethod.assessment_due_date) { setDueDateDPProps({ defaultValue: moment(props.assessmentQuestionsMethod.assessment_due_date, 'YYYY-MM-DD') }) } else { setDueDateDPProps({}) }


            setStart_date(props.assessmentQuestionsMethod.start_date)
            setDue_date(props.assessmentQuestionsMethod.assessment_due_date)
        }
    }, [props.assessmentQuestionsMethod]);

    useEffect(() => {
        if (props.assessmentQuestionsMethod) {
            if (start_date != props.assessmentQuestionsMethod.assessment_start_date || due_date != props.assessmentQuestionsMethod.assessment_due_date) {
                setShowSaveButton(true)
            } else {
                setShowSaveButton(false)
            }
        }

    }, [start_date, due_date])

    const onFinish = async () => {
        const resultAction = await dispatch(
            EditAssessmentMethod({
                modified: { start_date, due_date }, id: assessmentMethod_id
            })
        );
        if (EditAssessmentMethod.fulfilled.match(resultAction)) {
            openNotificationWithIcon("success", `updated successfully`);
            dispatch(fetchAssessmentQuestionsMethods(props.course_id));
        } else {
            if (resultAction.payload) {
                setHandledError(resultAction.payload);
                handleOpenErrorModal();
            } else {
                setHandledError(resultAction.error);
                handleOpenErrorModal();
            }
        }
    };

    console.log("start_date", start_date)
    console.log("due_date", due_date)


    console.log("startDateDPProps", startDateDPProps)
    console.log("dueDateDPProps", dueDateDPProps)


    return (
        <>
            {props.assessmentQuestionsMethod && startDateDPProps && dueDateDPProps && <Form
                form={form}
                {...layout}
                onFinish={onFinish}
            >
                <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                        <Form.Item
                            name="start_date"
                            label="Strat date"
                        >
                            <DatePicker {...startDateDPProps}
                                // disabledDate={disabledDate} 
                                style={{ width: "100%" }}
                                onChange={handleStartDateChange} />
                        </Form.Item>
                    </GridItem>

                    <GridItem xs={12} sm={6} md={6}>
                        <Form.Item
                            name="due_date"
                            label="Due date"
                            validateStatus={due_date_validation_status}
                            help="The assessment due date should be greater than the assessment start date"

                        >
                            <DatePicker {...dueDateDPProps}
                                // disabledDate={disabledDate} 
                                style={{ width: "100%" }}
                                onChange={handleDueDateChange} />
                        </Form.Item>
                    </GridItem>
                    {showSaveButton && <GridItem xs={12} sm={12} md={12}>
                        <GridContainer justifyContent="flex-end">
                            <GridItem>
                                <Form.Item >
                                    <Button type="primary" color="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </GridItem>
                        </GridContainer>
                    </GridItem>}
                </GridContainer>
            </Form>}
            <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />
        </>
    );
}
