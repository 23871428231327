// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { SchoolRounded } from "@material-ui/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { Breadcrumb, Form, Input, notification, Select } from 'antd';
import "antd/dist/antd.css";
import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { fetchCodeLevels } from "reduxToolkit/features/CodeLevel/CodeLevelConfigSlice";
// @material-ui/icons
import {
  addNewSubCodeLevel, fetchSubCodeLevelById, fetchSubCodeLevels, updateSubCodeLevel
} from "../../../../reduxToolkit/features/SubCodeLevel/SubCodeLevelConfigSlice";

const useStyles = makeStyles(styles);

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 16,
    span: 16,
  },
};
// const classes = useStyles();
export default function CreatedSubCodeLevel({ match }) {
  const { t } = useTranslation();
  const { id } = match.params;
  const dispatch = useDispatch();
  console.log("id", id);
  const [form] = Form.useForm();
  const isAddMode = isNaN(id);
  console.log("id from match is add mode", isAddMode);
  const classes = useStyles();
  const useTitleStyles = makeStyles(titleStyles);

  const titleClasses = useTitleStyles();

  const [level, setLevel] = useState("");
  const [code_level, setCode_level] = useState("");
  const [levelClo_id, setLevelClo_id] = useState("");
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const onLevelChanged = (e) => setLevel(e.target.value);

  const onCode_levelChanged = (e) => setCode_level(e.target.value);

  const history = useHistory();
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };
  useEffect(() => {

    dispatch(fetchCodeLevels())


  }, []);
  const onFinish = async (values) => {
    const resultAction = await dispatch(
      addNewSubCodeLevel({ ...values })
    );
    if (addNewSubCodeLevel.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `Added successfully`);
      form.resetFields();
      dispatch(fetchSubCodeLevels());
      history.push(`/citrine/configuration/course/subCodeLevels`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }

  };

  const onFinishUpdate = async (values) => {
    const resultAction = await dispatch(
      updateSubCodeLevel({
        id: id,
        modified: {
          ...values,
        },
      })
    );
    if (updateSubCodeLevel.fulfilled.match(resultAction)) {
      openNotificationWithIcon("success", `edit successfully`);
      form.resetFields();
      dispatch(fetchSubCodeLevels());
      history.push(`/citrine/configuration/course/subCodelevels`);
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
      }
    }
  };


  useEffect(() => {
    if (!isAddMode) {
      dispatch(fetchSubCodeLevelById(id))
        .then(unwrapResult)
        .then((x) => {
          console.log(x, "x");
          form.setFieldsValue({
            level: x.level,
            code_level: x.code_level,
            levelClo_id: x.levelClo_id,
          });
        });
    }
  }, [id]);



  const SubCodeLevel = useSelector((state) => state.subLevelClo.SubCodeLevel);
  const codeLevelsClos = useSelector((state) => state.CodeLevel.codeLevels)
  console.log("SubCodeLevel ", SubCodeLevel);
  console.log("codeLevelsClos ", codeLevelsClos);


  return (
    <div>
      {isAddMode && (
        <div>
          <GridContainer

            md={12}
            xs={12} sm={12}
            direction="row"
            // style={{ width:300 ,height:150}}
            justify="space-between"
            alignItems="center"
          >
            <GridItem>

              <Breadcrumb separator=">">
                <Breadcrumb.Item >

                  {t('Configurations')}

                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`/citrine/configuration/course`}>
                    <span > {t('Course Configurations')} </span>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`/citrine/configuration/course/subCodeLevels`}>
                    <span > {t('Code SubLevels')} </span>
                  </Link>
                </Breadcrumb.Item>
              </Breadcrumb>
            </GridItem>

          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <SchoolRounded />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>


                    {t("Create")}

                  </h4>
                </CardHeader>
                <CardBody>
                  <Form
                    {...layout}
                    form={form}
                    name="control-hooks"
                    onFinish={onFinish}
                  >
                    <Form.Item
                      name="levelClo_id"
                      label="Level Clo"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select placeholder="Select Level Clo" allowClear>
                        {codeLevelsClos &&
                          codeLevelsClos.map((r) => (
                            <option key={r.id} value={r.id}>
                              {r.level}
                            </option>
                          ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="level"
                      label="Level"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>

                    <Form.Item
                      name="code_level"
                      label="Code Level"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>


                    <Form.Item {...tailLayout}>
                      <Button
                        type="primary"
                        style={{ marginRight: "20px" }}
                        color="primary"
                        onClick={() =>
                          history.push(`/citrine/configuration/course/subCodeLevels`)
                        }
                        htmlType="submit"
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        color="primary"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      )}
      {!isAddMode && SubCodeLevel && (
        <div>
          <GridContainer

            md={12}
            xs={12} sm={12}
            direction="row"
            // style={{ width:300 ,height:150}}
            justify="space-between"
            alignItems="center"
          >
            <GridItem>

              <Breadcrumb separator=">">
                <Breadcrumb.Item >

                  {t('Configurations')}

                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`/citrine/configuration/course`}>
                    <span > {t('Course Configurations')} </span>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`/citrine/configuration/course/subCodeLevels`}>
                    <span > {t('Code Levels')} </span>
                  </Link>
                </Breadcrumb.Item>
              </Breadcrumb>
            </GridItem>

          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <SchoolRounded />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>

                    {t("Edit")}

                  </h4>
                </CardHeader>
                <CardBody>
                  <Form
                    {...layout}
                    form={form}
                    name="control-hooks"
                    onFinish={onFinishUpdate}
                  >
                    <Form.Item
                      name="levelClo"
                      label="Level Clo"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select placeholder="Select Level Clo" allowClear>
                        {codeLevelsClos &&
                          codeLevelsClos.map((r) => (
                            <option key={r.id} value={r.id}>
                              {r.level}
                            </option>
                          ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="level"
                      label="Level"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>

                    <Form.Item
                      name="code_level"
                      label="Code Level"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>


                    <Form.Item {...tailLayout}>
                      <Button
                        type="primary"
                        style={{ marginRight: "20px" }}
                        color="primary"
                        onClick={() =>
                          history.push(`/citrine/configuration/course/subCodeLevels`)
                        }
                        htmlType="submit"
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        color="primary"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>


                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      )}
    </div>
  );
}
