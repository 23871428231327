// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {Breadcrumb, notification} from "antd";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
// @material-ui/icons
// core components
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {fetchProgramById} from "../../../../reduxToolkit/features/program/ProgramSpecSlice";
import ErrorModal from "../../../Components/ErrorModal";
import AddSurvey from "../../Planning/ProgramAssessment/AddSurvey";
import CustomDropdown from "../../../../components/CustomDropdown/CustomDropdown";
import {unlinkSurveyTemplatefromProgram} from "../../../../reduxToolkit/features/SurveyMapping/SurveyMappingSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import SuperGeneralDeleteModal from "../../../Components/SuperGeneralDeleteModal";

const useStyles = makeStyles(styles);

const openNotificationWithIcon = (type, msg) => {
    notification[type]({
        message: type,
        description: msg,
        top: 50,
        duration: 2,
    });
};

const SurveyTemplateExcerpt = ({ Element, program, disabledStatus, isEditor, fetchProgram }) => {

    const { t } = useTranslation();
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [handledError, setHandledError] = useState("");

    const dispatch = useDispatch();

    const history = useHistory();

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const handleOpenDeleteModal = () => {
        setOpen(true);
    };

    const handleCloseDeleteModal = () => {
        setOpen(false);
    };

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };

    const handleDelete = () => {
        dispatch(unlinkSurveyTemplatefromProgram({
            surveyTemplate_ids: [Element?.id],
            programspec_id: program?.id
        }))
            .then(unwrapResult)
            .then((originalPromiseResult) => {
                // handle result here
                openNotificationWithIcon('success', `Deleted`)
                dispatch(fetchProgram(program?.id));
            })
            .catch((rejectedValueOrSerializedError) => {
                setHandledError(rejectedValueOrSerializedError)
                handleOpenErrorModal()
            })
    };

    return (
        <>
            <GridItem key={Element?.id} xs={12} sm={6} md={4}>
                <Card style={{ height: 200 }}>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={10}>
                                <h4
                                    className={classes.cardProductTitle}
                                >
                                    {t("Survey template")}:&nbsp;
                                    <Link to={'#'}>
                                        {Element?.name}
                                    </Link>
                                </h4>
                                <h4
                                    className={classes.cardProductTitle}
                                >
                                    {t("Type")} : {Element?.type}
                                </h4>
                            </GridItem>
                            {isEditor && (
                                <GridItem xs={12} sm={12} md={2} style={{ zIndex: 1 }}>
                                    {disabledStatus ? <></> :
                                        <CustomDropdown isFilled isButtonIcon
                                                        dropdownList={[
                                                            {
                                                                text: "Mapping configuration",
                                                                onClickHandler() {
                                                                    history.push({
                                                                        pathname: `/citrine/specification/program/${program?.id}/surveyTemplates/${Element?.id}/mapping/`,
                                                                        state: {program: program}
                                                                    })
                                                                }
                                                            },
                                                            {
                                                                text: "Delete",
                                                                onClickHandler() {
                                                                    handleOpenDeleteModal();
                                                                }
                                                            }
                                                        ]}
                                        />
                                    }
                                </GridItem>
                            )}
                        </GridContainer>
                    </CardBody>
                </Card>
                <ErrorModal
                    open={openErrorModal}
                    handleClose={handleCloseErrorModal}
                    handledError={handledError}
                />
            </GridItem>
            <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />
            <SuperGeneralDeleteModal
                delete_Element={handleDelete}
                open={open}
                handleClose={handleCloseDeleteModal}
            />
        </>
    );
};

export default function SurveyTemplates({ match }) {

    const { id } = match.params;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [isEditor, setIsEditor] = useState(true);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [handledError, setHandledError] = useState("");
    const [disabledStatus, setDisabledStatus] = useState(false);

    const program = useSelector((state) => state.programSpec.program);
    const programStatus = useSelector((state) => state.programSpec.programStatus);

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const fetchProgram = async (id) => {
        try {
            await dispatch(fetchProgramById(id)).unwrap();
        } catch (err) {
            setHandledError(err);
            handleOpenErrorModal();
        }
    };

    useEffect(() => {
        fetchProgram(id);
        if (localStorage.getItem("state") !== "Draft") {
            setDisabledStatus(true);
        }
    }, []);

    let content;

    if (programStatus === "loading") {
        content = <div className="loader">{t("Loading...")}</div>;
    } else if (programStatus === "succeeded") {
        if (program?.surveytemplate_details) {
            content = program.surveytemplate_details?.map((Element) => (
                <SurveyTemplateExcerpt
                    key={Element.id}
                    Element={Element}
                    program={program}
                    disabledStatus={disabledStatus}
                    isEditor={isEditor}
                    fetchProgram={fetchProgram}
                />
            ));
        } else {
            content = <h5>{t("This Program has no survey templates!")}</h5>;
        }
    }
    return (
        <div>
            <GridItem>
                <Breadcrumb separator=">">
                    <Breadcrumb.Item>
                        <Link to={`/citrine/programSpec`}>{t("Programs List")}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/citrine/programSpec/${program?.id}`}>
                            {program?.program_degree_name}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link
                            to={`/citrine/specification/program/${program?.id}/surveyTemplates/`}
                            style={{ color: "orange" }}
                        >
                            {t("Survey templates List")}
                        </Link>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </GridItem>
            <GridContainer>
                {isEditor && (
                    <GridItem>
                        {disabledStatus ? <></> :
                            <AddSurvey programspec_id={program?.id} />
                        }
                    </GridItem>
                )}
            </GridContainer>
            <GridContainer>{content}</GridContainer>
            <ErrorModal
                open={openErrorModal}
                handleClose={handleCloseErrorModal}
                handledError={handledError}
            />
        </div>
    );
}
