// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Breadcrumb,
  Form,
  Input,
  InputNumber,
  message,
  notification,
  Select,
} from 'antd'
import 'antd/dist/antd.css'
import 'assets/css/styles.css'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardIcon from 'components/Card/CardIcon.js'
import Button from 'components/CustomButtons/Button.js'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import styles from '../../../../assets/jss//material-dashboard-pro-react/views/extendedTablesStyle'
import {
  grayColor,
  infoColor,
} from '../../../../assets/jss/material-dashboard-pro-react'
import {
  fetchUniversities,
  fetchUniversityById,
} from '../../../../reduxToolkit/features/university/UniversitiesSlice'
// @material-ui/icons
import { fetchLevels } from 'reduxToolkit/features/level/LevelSlice'
import {
  courseUpdate,
  courseUpdateWeeks,
  fetchCourseById,
  fetchCourses,
} from '../../../../reduxToolkit/Course/coursesSpecSlice'
import { fetchAreas } from '../../../../reduxToolkit/features/area/AreaSpecSlice'
import { fetchSubAreas } from '../../../../reduxToolkit/features/subarea/SubAreaSpecSlice'

import ErrorModal from '../../../Components/ErrorModal'

const useStyles = makeStyles(styles)

export default function UpdateCourseSpec({ match }) {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const classes = useStyles()

  // const [title, setTitle] = useState('')
  // const [code, setCode] = useState('')
  // const [academic_year, setAcademic_year] = useState('')
  // const [level_id, setLevel] = useState('')
  const [type, setType] = useState('')

  // const [category, setCategory_id] = useState('')
  // const [yearSemesterConfig_id, setYearSemesterConfig_id] = useState('')
  // const [credit_hours, setCredit_hours] = useState('')
  // const [description, setDescription] = useState('')
  // const [main_objectives, setMain_objectives] = useState('')
  // const [student_academic_counseling_and_support, setStudent_academic_counseling_and_support] = useState('')
  // const [students_development_and_participation, setStudent_development_and_participation] = useState('')
  // const [assessment_details_and_rubics, setAssessment_details_and_rubrics] = useState('')
  // const [department_ids, setDepartment_id] = useState([])
  const [institution_id, setInstitution_id] = useState('')
  const [prerequisites_ids, setPrerequisites_ids] = useState([])
  const [co_requisites_ids, setCo_requisites_ids] = useState([])
  const [areaSpec_ids, setAreaSpec_ids] = useState([])
  const [subAreaSpec_ids, setSubAreaSpec_ids] = useState([])
  const [coordinator_id, setCoordinator_id] = useState('')
  // const [program_structure, setProgram_structure] = useState('')
  const [field, setField] = useState(false)
  // const [college_ids, setCollege_id] = useState([])
  // const [submission_of_assessment, setSubmission_of_assessment] = useState('')
  // const [addRequestStatus, setAddRequestStatus] = useState('idle')

  const [mappedLevels, setMappedLevels] = useState(null)

  const dispatch = useDispatch()
  const { id } = match.params
  const areasStatus = useSelector((state) => state.areaSpec.areasStatus)
  const subAreasStatus = useSelector(
    (state) => state.subAreaSpec.subAreasStatus,
  )
  const universitiesStatus = useSelector(
    (state) => state.university.universitiesStatus,
  )
  const coursesStatus = useSelector(
    (state) => state.CourseSpec.coursesSpecificationStatus,
  )
  const { TextArea } = Input
  const [courses_reduced, setCourses_reduced] = useState([])
  const [prerequisites_idsFilled, setPrerequisites_idsFilled] = useState(true)
  const [co_requisites_idsFilled, setCo_requisites_idsFilled] = useState(true)
  const [
    prerequisites_idsAndCo_requisites_idsFilled,
    setPrerequisites_idsAndCo_requisites_idsFilled,
  ] = useState(true)

  useEffect(() => {
    dispatch(fetchCourseById(id))
      .then(unwrapResult)
      .then((course) => {
        // do additional work
        if (course.prerequisites_ids.length < 1) {
          setPrerequisites_idsAndCo_requisites_idsFilled(false)
        }
        if (course.co_requisites_ids.length < 1) {
          setPrerequisites_idsAndCo_requisites_idsFilled(false)
        }

        //console.log("prerequisites_ids : ", course.prerequisites_ids, course.prerequisites_ids.length>0)
        //console.log("co_requisites_ids : ", course.co_requisites_ids, course.co_requisites_ids.length>0)
        form.setFieldsValue({
          title: course.title,
          code: course.code,
          subAreaSpec_ids: course.subAreaSpec_ids,
          credit_hours: course.credit_hours,
          description: course.description,
          main_objectives: course.main_objectives,
          student_academic_counseling_and_support:
            course.student_academic_counseling_and_support,
          // students_development_and_participation: course.students_development_and_participation,
          // assessment_details_and_rubics: course.assessment_details_and_rubics,
          institution_id: course.institution_id,
          department_ids: course.department_ids,
          college_ids: course.college_ids,
          prerequisites_ids: course.prerequisites_ids,
          co_requisites_ids: course.co_requisites_ids,
          category: course.category,
          program_structure: course.program_structure,
          type: course.type,
          level_id: course.level_id,
          number_of_weeks: course.number_of_weeks,
          number_of_days: course.number_of_days,
          number_of_hours: course.number_of_hours,
          decision_making: course.decision_making,
          follow_up: course.follow_up,
          // office: course.office,
          // office_hours: course.office_hours,
          // students_attendance: course.students_attendance,
          // course_schedule: course.course_schedule,
          // welcoming_message: course.welcoming_message,
          // faculty_availability: course.faculty_availability,
          // course_resources_and_interactions: course.course_resources_and_interactions,
          // class_participation: course.class_participation,
          // additional_recommended_readings: course.additional_recommended_readings,
          // assessment_strategy: course.assessment_strategy,
          // delivery_of_assessment: course.delivery_of_assessment,
          // evaluation_of_assessment: course.evaluation_of_assessment,
          // academic_integrity: course.academic_integrity,
          // submission_of_assessment: course.submission_of_assessment,
          // yearSemesterConfig_id: course.yearSemesterConfig_id,
        })
        setCoordinator_id(course.coordinator_id)
        setAreaSpec_ids(course.areaSpec_ids)
        // setSubAreaSpec_ids(course.subAreaSpec_ids)

        if (course.type == 'Field Experience') {
          setField(true)
        } else {
          setField(false)
        }

        dispatch(fetchUniversityById(course.institution_id))
      })
  }, [])

  const course = useSelector((state) => state.CourseSpec.courseSpecification)

  useEffect(() => {
    if (universitiesStatus === 'idle') {
      dispatch(fetchUniversities())
    }
    if (areasStatus === 'idle') {
      dispatch(fetchAreas())
    }
    if (subAreasStatus === 'idle') {
      dispatch(fetchSubAreas())
    }
    dispatch(fetchLevels())
      .unwrap()
      .then((originalPromiseResult) => {
        // handle result here
        setMappedLevels(originalPromiseResult)
      })
    if (coursesStatus === 'idle') {
      dispatch(fetchCourses())
    }
  }, [universitiesStatus, areasStatus, subAreasStatus, coursesStatus, dispatch])

  const universities = useSelector((state) => state.university.universities)
  const courses = useSelector((state) => state.CourseSpec.coursesSpecification)
  const areas = useSelector((state) => state.areaSpec.areas)
  const subAreas = useSelector((state) => state.subAreaSpec.subAreas)
  const history = useHistory()

  const [selectedSubAreas, setSelectedSubAreas] = useState([])
  const [selectedAreas, setSelectedAreas] = useState([])

  useEffect(() => {
    const preselectedSubAreas =
      subAreas &&
      subAreas.filter((subArea) => subAreaSpec_ids.indexOf(subArea.id) !== -1)
    setSelectedSubAreas(preselectedSubAreas)
    const preAreaSpec_ids = [
      ...new Set(
        preselectedSubAreas.map((selectedSubArea) => {
          return selectedSubArea.areaSpec_id
        }),
      ),
    ]
    setAreaSpec_ids(preAreaSpec_ids)
    const preselectedAreas =
      areas && areas.filter((area) => preAreaSpec_ids.indexOf(area.id) !== -1)

    setSelectedAreas(preselectedAreas)
    let tab = []
    preselectedAreas.map(
      (area) =>
        area.levels.length && area.levels.map((level) => tab.push(level)),
    )
    const result = [...new Set(tab.map((a) => JSON.stringify(a)))].map((a) =>
      JSON.parse(a),
    )
    setMappedLevels(result)
  }, [subAreaSpec_ids])
  useEffect(() => {
    const course_index = courses.findIndex((obj) => obj.id == id)
    //courses.reduce((acc, obj, i) => (obj.id ==! course_index) ? acc.push(i) : acc, [])
    //const courses_list_filtered = courses.reduce((acc, obj) => {(obj.id ==! course_index) ? acc.push(obj) : acc, [];

    // });
    // console.log('courses_list_filtered', courses_list_filtered)
  }, [id, courses])

  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [handledError, setHandledError] = React.useState('')

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true)
  }
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    })
  }

  const onFinishFailed = (errorInfo) => {
    message.error('Submit failed!')
  }

  // function onChangeCollege(value) {
  //     console.log(`selected ${value}`);
  //     setCollege_id(value);
  // }
  const [courseSpec_id, setCourseSpec_id] = useState(id)
  const onFinish = async (values) => {
    console.log(values)

    let course

    if (
      values.prerequisites_ids &&
      values.prerequisites_ids.length &&
      values.co_requisites_ids &&
      values.co_requisites_ids.length
    ) {
      course = { ...values, areaSpec_ids, coordinator_id, courseSpec_id }
      //co_requisites_ids : [1,2,3,]
    } else {
      if (prerequisites_idsAndCo_requisites_idsFilled) {
        course = { ...values, areaSpec_ids, coordinator_id, courseSpec_id }
        //co_requisites_ids : []
      } else {
        const { prerequisites_ids, co_requisites_ids, ...valuesRest } = values
        course = { ...valuesRest, areaSpec_ids, coordinator_id, courseSpec_id }
        // without co_requisites_ids
      }
    }
    console.log(course)

    const resultAction = await dispatch(
      courseUpdate({ id: id, modified: course }),
    )

    dispatch(courseUpdateWeeks(course))
    if (courseUpdate.fulfilled.match(resultAction)) {
      // user will have a type signature of User as we passed that as the Returned parameter in createAsyncThunk
      const course = resultAction.payload

      openNotificationWithIcon('success', `Edited ${course.title}`)
      history.push('/citrine/CourseSpecificationList')
    } else {
      if (resultAction.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, those types will be available here.

        setHandledError(resultAction.payload)
        handleOpenErrorModal()
      } else {
        setHandledError(resultAction.error)
        handleOpenErrorModal()
      }
    }
  }
  const onChangeSubArea = (ids) => {
    setSubAreaSpec_ids(ids)
  }

  function onChangeUniversity(value) {
    //  console.log(`selected ${value}`);
    setInstitution_id(value)
    dispatch(fetchUniversityById(value))
  }

  const university = useSelector((state) => state.university.university)

  // function onChangeDepartment(value) {
  //     console.log(`selected ${value}`);
  //     setDepartment_id(value);
  // }
  // function onChangelevel(value) {
  //     console.log(`selected ${value}`);
  //     setLevel(value);
  // }
  // function onChangeCategory(value) {
  //     console.log(`selected ${value}`);
  //     setCategory_id(value);
  // }

  function onChangePrerequisites_ids(value) {
    console.log(`selected ${value}`)
    setPrerequisites_ids(value)
  }
  function onChangeCo_requisites_ids(value) {
    console.log(`selected ${value}`)
    setCo_requisites_ids(value)
  }
  function onChangeType(value) {
    setType(value)
    if (value == 'Field Experience') {
      setField(true)
    } else {
      setField(false)
    }
  }

  const { Option } = Select

  return (
    <div>
      <GridContainer
        style={{
          borderBottom: `1px solid  ${grayColor[15]}`,
          borderTop: `1px solid  ${grayColor[15]}`,
        }}
        justify="space-between"
        alignItems="center"
      >
        <GridItem>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              <Link to={`/citrine/CourseSpecificationList/`}>
                {t('Courses List')}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/citrine/courseSpecDetails/${id}`}>
                {course && course.title && course.title}
              </Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link
                style={{ color: 'orange' }}
                to={`/citrine/courseSpecUpdate/${id}`}
              >
                Edit
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </GridItem>
      </GridContainer>
      <Form
        name="basic"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError
      >
        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <LibraryBooksIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{`Update course  ${
                  course && course.title && course.title
                }`}</h4>
              </CardHeader>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={10}>
            <h3>
              <small style={{ color: `${infoColor[0]}` }}>
                Course General Information
              </small>
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <Form.Item
                      label={t('Code')}
                      name="code"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's code",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={8}>
                    <Form.Item
                      label={t('Title')}
                      name="title"
                      // hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's title",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t('Credit hours')}
                      name="credit_hours"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's credit hours",
                        },
                      ]}
                    >
                      <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      name="number_of_weeks"
                      label="Number of weeks "
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <InputNumber autoSize={true} style={{ width: '100%' }} />
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Form.Item
                      label={t('Sub Area')}
                      name="subAreaSpec_ids"
                      rules={[
                        {
                          required: false,
                          message: "Please input the course's area and subarea",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        optionFilterProp="children"
                        onChange={onChangeSubArea}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {subAreas &&
                          subAreas.map((subarea) => (
                            <Option key={subarea.id} value={subarea.id}>
                              {`${subarea.areaSpec_detail.name} /  ${subarea.name}`}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <Form.Item
                      label={t('Level')}
                      name="level_id"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's level",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        // onChange={onChangelevel}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* {mappedLevels && mappedLevels.map(area => (
                                                    area.levels && area.levels.map((level) => (
                                                        <Option key={level.id} value={level.id}>
                                                            {level.name}
                                                        </Option>
                                                    ))))} */}

                        {mappedLevels &&
                          mappedLevels.map((level) => (
                            <Option key={level.id} value={level.id}>
                              {level.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <Form.Item
                      label={t('Category')}
                      name="category"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's type",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        // onChange={onChangeCategory}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option value="Required">Required</Option>
                        <Option value="Elective">Elective</Option>
                        {/* {selectedAreas && selectedAreas.map(area => (
                                                    area.types && area.types.map((type) => (
                                                        <Option key={type.id} value={type.id}>
                                                            {type.name}
                                                        </Option>
                                                    ))))} */}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t('Prerequisites')}
                      name="prerequisites_ids"
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        loading
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={onChangePrerequisites_ids}
                      >
                        {courses &&
                          courses.map((course) => (
                            <Option key={course.id} value={course.id}>
                              {course.code}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t('Co_requisites')}
                      name="co_requisites_ids"
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        loading
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={onChangeCo_requisites_ids}
                      >
                        {courses &&
                          courses.map((course) => (
                            <Option key={course.id} value={course.id}>
                              {course.code}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t('Type')}
                      name="type"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's type",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        onChange={onChangeType}
                        // optionFilterProp="children"
                        // filterOption={(input, option) =>
                        //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        // }
                      >
                        <Option value="Conventional Course">
                          Conventional Course
                        </Option>
                        <Option value="Final Year Project">
                          Final Year Project
                        </Option>
                        <Option value="Senior Design">Senior Design</Option>
                        <Option value="Field Experience">
                          Field Experience
                        </Option>
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <Form.Item
                      label={t('Program Structure')}
                      name="program_structure"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please input the course's Program Structure",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option value={'Institution Requirements'}>
                          {'Institution Requirements'}
                        </Option>
                        <Option value={'College Requirements'}>
                          {'College Requirements'}
                        </Option>
                        <Option value={'Program Requirements'}>
                          {'Program Requirements'}
                        </Option>
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t('Description')}
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's description",
                        },
                      ]}
                    >
                      <TextArea rows={4} maxLength={100000} />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t('Main objectives')}
                      name="main_objectives"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's main objectives",
                        },
                      ]}
                    >
                      <TextArea rows={4} maxLength={100000} />
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t('student academic counseling and support')}
                      name="student_academic_counseling_and_support"
                      rules={[
                        {
                          required: false,
                          message:
                            "Please input the course's student academic counseling and support",
                        },
                      ]}
                    >
                      <TextArea rows={4} placeholder="" maxLength={100000} />
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={10}>
            {field && (
              <div>
                <h3>
                  <small style={{ color: `${infoColor[0]}` }}>
                    Dates and times allocation of field experience activities
                  </small>
                </h3>
              </div>
            )}
          </GridItem>

          <GridItem xs={12} sm={12} md={10}>
            {field && (
              <div>
                <Card>
                  <CardBody>
                    <GridContainer>
                      {/* <GridItem xs={12} sm={12} md={4}>



                                            <Form.Item
                                                name="number_of_weeks"
                                                label="Number of weeks "
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <InputNumber autoSize={true} style={{ width: "100%" }} />
                                            </Form.Item>
                                        </GridItem> */}
                      <GridItem xs={12} sm={12} md={6}>
                        <Form.Item
                          name="number_of_days"
                          label="Number of days"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <InputNumber
                            autoSize={true}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <Form.Item
                          name="number_of_hours"
                          label="Number of hours"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <InputNumber
                            autoSize={true}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t(
                            'Decision-making procedures for identifying appropriate locations for field experience',
                          )}
                          name="decision_making"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please input the course's Decision-making procedures for identifying appropriate locations for field experience",
                            },
                          ]}
                        >
                          <TextArea rows={4} maxLength={100000} />
                        </Form.Item>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <Form.Item
                          label={t('Supervision and Follow-up Mechanism')}
                          name="follow_up"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please input the course's Supervision and Follow-up Mechanism",
                            },
                          ]}
                        >
                          <TextArea rows={4} maxLength={100000} />
                        </Form.Item>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </div>
            )}
          </GridItem>

          <GridItem xs={12} sm={12} md={10}>
            <h3>
              <small style={{ color: `${infoColor[0]}` }}>
                Institutional Information
              </small>
            </h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <Form.Item
                      label={t('Institution')}
                      name="institution_id"
                      rules={[
                        {
                          required: true,
                          message: "Please input the course's institution",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        onChange={onChangeUniversity}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {universities.map((university) => (
                          <Option key={university.id} value={university.id}>
                            {university.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={4} sm={4} md={6}>
                    <Form.Item
                      label={t('Colleges')}
                      name="college_ids"
                      rules={[
                        {
                          required: false,
                          message: "Please input the program's colleges",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        showArrow
                        style={{ width: '100%' }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {university &&
                          university.colleges &&
                          university.colleges.map((college) => (
                            <Option key={college.id} value={college.id}>
                              {college.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </GridItem>

                  <GridItem xs={4} sm={4} md={6}>
                    <Form.Item label={t('Departments')} name="department_ids">
                      <Select
                        mode="multiple"
                        showSearch
                        showArrow
                        style={{ width: '100%' }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {university &&
                          university.colleges &&
                          university.colleges.map(
                            (college) =>
                              college &&
                              college.departments &&
                              college.departments.map((department) => (
                                <Option
                                  key={department.id}
                                  value={department.id}
                                >
                                  {department.name}
                                </Option>
                              )),
                          )}
                      </Select>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          {/* <GridItem xs={12} sm={12} md={10}>
                        <h3>
                            <small style={{ color: `${infoColor[0]}` }}>
                                Office Information
                            </small>
                        </h3>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={9}>
                                        <Form.Item
                                            label={t("Office ")}
                                            name="office"
                                            rules={[{ required: false, message: "Please input the course's office" }]}
                                        >
                                            <TextArea rows={1} placeholder="" maxLength={100000}
                                            />
                                        </Form.Item>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3}>
                                        <Form.Item
                                            label={t("Office hours")}
                                            name="office_hours"
                                            rules={[{ required: false, message: "Please input the course's office hours" }]}
                                        >
                                            <Input.TextArea
                                                autoSize={true}
                                            />
                                        </Form.Item>
                                    </GridItem>

                                </GridContainer>
                            </CardBody >
                        </Card >
                    </GridItem>
                    <GridItem xs={12} sm={12} md={10}>
                        <h3>
                            <small style={{ color: `${infoColor[0]}` }}>
                                Syllabus Information
                            </small>
                        </h3>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Form.Item
                                            label={t("Year/Semester")}
                                            name="yearSemesterConfig_id"
                                            rules={[{ required: false, message: "Please input the course's student academic counseling and support" }]}
                                        >
                                            <Select
                                                showSearch
                                                optionFilterProp="children"
                                                onChange={onChangeYearSemester}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }

                                            >
                                                {yearSemesters.map((c) => (
                                                    <Option key={c.id} value={c.id}>
                                                        {c.libelle}
                                                    </Option>
                                                ))}

                                            </Select>
                                        </Form.Item>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Form.Item
                                            label={t("student academic counseling and support")}
                                            name="student_academic_counseling_and_support"
                                            rules={[{ required: true, message: "Please input the course's student academic counseling and support" }]}
                                        >
                                            <TextArea rows={4} placeholder="" maxLength={100000}
                                            />
                                        </Form.Item>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Form.Item
                                            label={t("Student development and participation")}
                                            name="students_development_and_participation"
                                            rules={[{ required: false, message: "Please input the course's student development and participation" }]}
                                        >
                                            <TextArea rows={4} placeholder="" maxLength={100000}
                                            />
                                        </Form.Item>
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={12}>
                                        <Form.Item
                                            label={t("Student Attendance")}
                                            name="students_attendance"
                                            rules={[{ required: false, message: "Please input the course's student attendance" }]}
                                        >
                                            <TextArea rows={4} placeholder="" maxLength={100000}
                                            />
                                        </Form.Item>

                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Form.Item
                                            label={t("Course schedule ")}
                                            name="course_schedule"
                                            rules={[{ required: false, message: "Please input the course's Course schedule" }]}
                                        >
                                            <TextArea rows={4} placeholder="" maxLength={100000}
                                            />
                                        </Form.Item>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Form.Item
                                            label={t("Welcoming message ")}
                                            name="welcoming_message"
                                            rules={[{ required: false, message: "Please input the course's Welcoming message" }]}
                                        >
                                            <TextArea rows={4} placeholder="" maxLength={100000}
                                            />
                                        </Form.Item>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Form.Item
                                            label={t("Faculty availability")}
                                            name="faculty_availability"
                                            rules={[{ required: false, message: "Please input the  Faculty availability" }]}
                                        >
                                            <TextArea rows={4} placeholder="" maxLength={100000}
                                            />
                                        </Form.Item>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Form.Item
                                            label={t("Course resources and interactions")}
                                            name="course_resources_and_interactions"
                                            rules={[{ required: false, message: "Please input the Course resources and interactions" }]}
                                        >
                                            <TextArea rows={4} placeholder="" maxLength={100000}
                                            />
                                        </Form.Item>
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={12}>
                                        <Form.Item
                                            label={t("Class participation")}
                                            name="class_participation"
                                            rules={[{ required: false, message: "Please input the Class participation" }]}
                                        >
                                            <TextArea rows={4} placeholder="" maxLength={100000}
                                            />
                                        </Form.Item>
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={12}>
                                        <Form.Item
                                            label={t("Additional recommended readings")}
                                            name="additional_recommended_readings"
                                            rules={[{ required: false, message: "Please input the Additional recommended readings" }]}
                                        >
                                            <TextArea rows={4} placeholder="" maxLength={100000}
                                            />
                                        </Form.Item>
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={12}>
                                        <Form.Item
                                            label={t("Assessment strategy")}
                                            name="assessment_strategy"
                                            rules={[{ required: false, message: "Please input the Assessment strategy" }]}
                                        >
                                            <TextArea rows={4} placeholder="" maxLength={100000}
                                            />
                                        </Form.Item>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Form.Item
                                            label={t("Assessment details and rubrics")}
                                            name="assessment_details_and_rubics"
                                            rules={[{ required: false, message: "Please input the Assessment strategy" }]}
                                        >
                                            <TextArea rows={4} placeholder="" maxLength={100000}
                                            />
                                        </Form.Item>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Form.Item
                                            label={t("Delivery of assessment")}
                                            name="delivery_of_assessment"
                                            rules={[{ required: false, message: "Please input the Assessment strategy" }]}
                                        >
                                            <TextArea rows={4} placeholder="" maxLength={100000}
                                            />
                                        </Form.Item>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Form.Item
                                            label={t("Evaluation of assessment")}
                                            name="evaluation_of_assessment"
                                            rules={[{ required: false, message: "Please input the Evaluation of assessment" }]}
                                        >
                                            <TextArea rows={4} placeholder="" maxLength={100000}
                                            />
                                        </Form.Item>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Form.Item
                                            label={t("Academic integrity ")}
                                            name="academic_integrity"
                                            rules={[{ required: false, message: "Please input the Academic integrity " }]}
                                        >
                                            <TextArea rows={4} placeholder="" maxLength={100000}
                                            />
                                        </Form.Item>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Form.Item
                                            label={t("Submission of assessment")}
                                            name="submission_of_assessment"
                                            rules={[{ required: false, message: "Please input the Grading " }]}
                                        >
                                            <TextArea rows={4} placeholder="" maxLength={100000}
                                            />
                                        </Form.Item>
                                    </GridItem>
                                </GridContainer>
                            </CardBody >
                        </Card >
                    </GridItem> */}
        </GridContainer>

        {/* </FormControl> */}

        <GridContainer direction="row" justify="center" alignItems="center">
          <GridItem xs={12} sm={12} md={10}>
            <GridContainer justify="flex-end">
              <GridItem>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="submit" color="primary">
                    {t('Save')}
                  </Button>
                </Form.Item>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Form>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  )
}
