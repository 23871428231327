// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { SchoolRounded } from "@material-ui/icons";
import { unwrapResult } from '@reduxjs/toolkit';
import { Breadcrumb, Checkbox, Select } from 'antd';
import 'antd/dist/antd.css';
import "assets/css/styles.css";
import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { fetchAreas } from "reduxToolkit/features/area/AreaSpecSlice";
import { addNewFacilitiesRequiredConfig, facilityUpdate, fetchFacilitiesRequiredConfigById, fetchFacilitiesRequiredsConfig } from '../../../../reduxToolkit/features/FacilitiesRequired/FacilitiesRequiredConfigSlice';


const useStyles = makeStyles(styles);
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};
// const classes = useStyles();
export default function CreateFacilitiesRequiredConfig({ match }) {
    const { t } = useTranslation();
    const { id } = match.params

    console.log('id', id)

    const isAddMode = isNaN(id);
    console.log('id from match is add mode', isAddMode)
    const classes = useStyles();
    const useTitleStyles = makeStyles(titleStyles);

    useEffect(() => {
        dispatch(fetchAreas())
    }, [dispatch]
    )

    const [name, setName] = useState('')
    const [computing_resource, setIsComputing] = useState(false);
    const [areaSpec_ids, setAreaSpec_ids] = useState([])

    const [addRequestStatus, setAddRequestStatus] = useState('idle')

    const dispatch = useDispatch()
    const history = useHistory();

    const { Option } = Select;
    const canSave =
        [name, areaSpec_ids].every(Boolean) && addRequestStatus === 'idle'


    const onSaveClicked = async () => {
        try {
            setAddRequestStatus('pending')
            console.log('onsave')
            const resultAction = await dispatch(
                addNewFacilitiesRequiredConfig({ name, areaSpec_ids, computing_resource })
            )
            unwrapResult(resultAction)
            setName('')
            setAreaSpec_ids('')
            dispatch(fetchFacilitiesRequiredsConfig())
            history.push(`/citrine/FacilitiesRequiredConfigList`);
        } catch (err) {
            console.error('Failed to save Teaching Strategies  : ', err)
        } finally {
            history.push(`/citrine/FacilitiesRequiredConfigList`);
            setAddRequestStatus('idle')
        }


    }
    const onUpdateClicked = async () => {
        try {
            setAddRequestStatus('pending')
            console.log('onsave')
            console.log('isComputing', computing_resource)
            const resultAction = await dispatch(
                facilityUpdate({ id: id, modified: { name, areaSpec_ids, computing_resource } })
            )
            unwrapResult(resultAction)
            setName('')
            setAreaSpec_ids('')
            dispatch(fetchFacilitiesRequiredsConfig())
            history.push(`/citrine/FacilitiesRequiredConfigList`);
        } catch (err) {
            console.error('Failed to save facilitiesRequiredConfig  : ', err)
        } finally {
            history.push(`/citrine/FacilitiesRequiredConfigList`);
            setAddRequestStatus('idle')
        }


    }

    function onChangeAreaSpec_ids(value) {
        //  console.log(`selected ${value}`);
        setAreaSpec_ids(value);
    }

    function onBlur() {
        //   console.log('blur');
    }

    function onFocus() {
        //  console.log('focus');
    }

    function onSearch(val) {
        //  console.log('search:', val);
    }
    const areas = useSelector((state) => state.areaSpec.areas)


    useEffect(() => {
        if (!isAddMode) {
            dispatch(fetchFacilitiesRequiredConfigById(id))
        }
    }, [id]);

    const facility = useSelector((state) => state.FacilitiesRequiredConfig.FacilityRequiredsConfig)
    console.log('facility ', facility)

    useEffect(() => {
        if (!isAddMode) {
            setName(facility && facility.name)
            setAreaSpec_ids(facility && facility.areaSpec_ids)

        }
    }, [facility, dispatch]);


    function onChangeIsComputing(e) {
        console.log(`checked = ${e.target.checked}`);
        setIsComputing(e.target.checked);

    }
    return (
        <div>
            {isAddMode &&
                <div>
                    <div>
                        <GridContainer >
                            <GridItem>
                                <GridItem>
                                    <Breadcrumb separator=">">
                                        <Breadcrumb.Item >

                                            {t('Configurations')}

                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item>
                                            <Link to={`/citrine/configuration/course`}  > {t('Course Configurations')}
                                            </Link>
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item>
                                            <Link to={`/citrine/FacilitiesRequiredConfigList`} style={{ color: "orange" }} > {t('Facilities Required item List')}
                                            </Link>
                                        </Breadcrumb.Item>


                                    </Breadcrumb>
                                </GridItem>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader color="info" icon>
                                        <CardIcon color="info">
                                            <SchoolRounded />
                                        </CardIcon>
                                        <h4 className={classes.cardIconTitle}>
                                            {t('Create')}
                                        </h4>
                                    </CardHeader>
                                    <CardBody>
                                        <form>
                                            <GridContainer xs={12} sm={12} md={12} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", color: "gray" }}>
                                                <GridItem xs={6} sm={6} md={9}>
                                                    <CustomInput
                                                        labelText={t('Name ')}
                                                        id="name"

                                                        type="text"
                                                        name="name"
                                                        value={name}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter a name !',
                                                            },
                                                        ]}
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            disabled: false,
                                                            onChange: event => {
                                                                setName(event.target.value);
                                                            }
                                                        }}
                                                    />
                                                    <GridItem xs={6} sm={6} md={9} style={{ width: "100%" }}>
                                                        <Checkbox onChange={onChangeIsComputing} style={{ width: "200%" }}>is it a Computing resources</Checkbox>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={9} >
                                                        <label>{t('Areas ')} </label>
                                                        <Select
                                                            showSearch
                                                            style={{ width: 200 }}
                                                            mode="multiple"

                                                            optionFilterProp="children"
                                                            name="areaSpec_ids"
                                                            value={areaSpec_ids}
                                                            onChange={onChangeAreaSpec_ids}
                                                            onFocus={onFocus}
                                                            onBlur={onBlur}
                                                            onSearch={onSearch}
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }

                                                        >
                                                            {areas.map((area) => (
                                                                <option key={area.id} value={area.id}>
                                                                    {area.name}
                                                                </option>
                                                            ))}

                                                        </Select>
                                                    </GridItem>


                                                    <GridItem>
                                                        <Button color="info"
                                                            className={classes.updateProfileButton}
                                                            onClick={onSaveClicked}
                                                        >
                                                            {t(' Save')}
                                                        </Button>
                                                    </GridItem>
                                                </GridItem>

                                            </GridContainer>
                                        </form>

                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>

                    </div>
                </div>
            }
            {!isAddMode && facility &&
                <div>

                    <div>
                        <GridContainer >
                            <GridItem>
                                <GridItem>
                                    <Breadcrumb separator=">">
                                        <Breadcrumb.Item >

                                            {t('Configurations')}

                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item>
                                            <Link to={`/citrine/configuration/course`}  > {t('Course Configurations')}
                                            </Link>
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item>
                                            <Link to={`/citrine/FacilitiesRequiredConfigList`} style={{ color: "orange" }} > {t('Facilities Required item List')}
                                            </Link>
                                        </Breadcrumb.Item>


                                    </Breadcrumb>
                                </GridItem>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader color="info" icon>
                                        <CardIcon color="info">
                                            <SchoolRounded />
                                        </CardIcon>
                                        <h4 className={classes.cardIconTitle}>
                                            {t('Edit')}
                                        </h4>
                                    </CardHeader>
                                    <CardBody>
                                        <form>
                                            <GridContainer xs={12} sm={12} md={12} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", color: "gray" }}>
                                                <GridItem xs={6} sm={6} md={9}>
                                                    <CustomInput
                                                        labelText={t('Name ')}
                                                        id="name"

                                                        type="text"
                                                        name="name"
                                                        value={name}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter a name !',
                                                            },
                                                        ]}
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            disabled: false,
                                                            onChange: event => {
                                                                setName(event.target.value);
                                                            },
                                                            value: name
                                                        }}
                                                    />

                                                    <GridItem xs={6} sm={6} md={9} style={{ width: "100%" }}>
                                                        <Checkbox onChange={onChangeIsComputing} style={{ width: "200%" }}>is it a Computing resources</Checkbox>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={9} >
                                                        <label>{t('Areas ')} </label>
                                                        <Select
                                                            showSearch
                                                            style={{ width: 200 }}
                                                            mode="multiple"

                                                            optionFilterProp="children"
                                                            name="areaSpec_ids"
                                                            value={areaSpec_ids}
                                                            onChange={onChangeAreaSpec_ids}
                                                            onFocus={onFocus}
                                                            onBlur={onBlur}
                                                            onSearch={onSearch}
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }

                                                        >
                                                            {areas.map((area) => (
                                                                <option key={area.id} value={area.id}>
                                                                    {area.name}
                                                                </option>
                                                            ))}

                                                        </Select>
                                                    </GridItem>


                                                    <GridItem>
                                                        <Button color="info"
                                                            className={classes.updateProfileButton}
                                                            onClick={onUpdateClicked}
                                                        >
                                                            {t('Update')}
                                                        </Button>
                                                    </GridItem>
                                                </GridItem>

                                            </GridContainer>
                                        </form>

                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>

                    </div>

                </div>
            }
        </div>
    );



}


