import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  addAnnualProgramReportDocument,
  deleteAnnualProgramReportDocument,
  fetchAnnualProgramReportDocuments,
} from "../../../../reduxToolkit/features/AnnualProgramReport/AnnualProgramReportDocumentSlice";
import {
  fetchAnnualProgramReportById,
  fetchAnnualProgramReports,
  UpdateAnnualProgramReport,
} from "../../../../reduxToolkit/features/AnnualProgramReport/AnnualProgramReportSlice";

import "antd/dist/antd.css";

// core components
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Card from "components/Card/Card.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Link, useParams } from "react-router-dom";

import { notification } from "antd";
import {
  grayColor,
  primaryColor,
} from "../../../../assets/jss/material-dashboard-pro-react";

import titleStyles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import ErrorModal from "../../../Components/ErrorModal";

import { unwrapResult } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";

import { ArrowRightOutlined } from "@ant-design/icons";
import { Avatar, Divider } from "antd";
import {
  blackColor,
  hexToRgb,
  whiteColor,
} from "assets/jss/material-dashboard-pro-react.js";
import Moment from "moment";
import { GoReport } from "react-icons/go";

import { Breadcrumb, Tabs } from "antd";
import { fetchActivities } from "../../../../reduxToolkit/features/Activitiy/ActivitiesSlice";
import PreviousActionPlan from "../../../Components/PreviousActionPlan";
import Identification from "./Identification";
import PLOsAssessment from "./PLOsAssessment/PLOsAssessment";
import ProgramStatistics from "./Program Statistics/ProgramStatistics";
import SummaryOfCourseReports from "./SummaryOfCourseReports/SummaryOfCourseReports";

import AddDocument from "./AddDocument";
import DifficultiesAndChallenges from "./DifficultiesAndChallenges";
import GenerateAnnualProgramActionsModal from "./GenerateAnnualProgramActionsModal";
import ProgramActivities from "./ProgramActivities/ProgramActivities";
import ProgramEvaluationPage from "./ProgramEvaluation/ProgramEvaluationPage";
import ProgramImprovementPlan from "./ProgramImprovementPlan";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";

const useStyles = makeStyles(styles);
const useTitleStyles = makeStyles(titleStyles);
const useCardStyles = makeStyles(cardStyles);

const { TabPane } = Tabs;

const useStyles1 = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 400,
    maxWidth: 300,
    backgroundColor: theme.palette.background.paper,
  },
}));
export default function AnnualProgramImprovementDetails() {
  const { t } = useTranslation();
  const classes = useStyles();
  const titleClasses = useTitleStyles();
  const cardClasses = useCardStyles();
  const { type, id, docId } = useParams();

  console.log("id", id);
  const classes1 = useStyles1();
  const dispatch = useDispatch();
  // const fetchAnnualProgramReport = async (id) => {
  //   try {
  //     const resultAction = await dispatch(fetchAnnualProgramReportById(id))
  //       .then(unwrapResult)
  //       .then((AnnualProgram) => {
  //         console.log(AnnualProgram, "AnnualProgram");
  //         localStorage.setItem(
  //           "AnnualProgram_State",
  //           AnnualProgram.state_object
  //         );
  //         setStateName(AnnualProgram && AnnualProgram.state_object);
  //         dispatch(fetchAnnualProgramReportDocuments(AnnualProgram.program));
  //       });
  //   } catch (err) {
  //     setHandledError(err);
  //     handleOpenErrorModal();
  //   }
  // };
  const [activeParentTabKey, setActiveParentTabKey] = useState(1);

  useEffect(() => {
    dispatch(fetchAnnualProgramReportById(id))
      .then(unwrapResult)
      .then((AnnualProgram) => {
        console.log(AnnualProgram, "AnnualProgram------------");
        localStorage.setItem("AnnualProgram_State", AnnualProgram.state_object);
        setStateName(AnnualProgram && AnnualProgram.state_object);
        dispatch(fetchAnnualProgramReportDocuments(AnnualProgram.program));
      });
    dispatch(fetchActivities());
  }, []);
  const [openAddDocumentModal, setOpenAddDocumentModal] = React.useState(false);
  const handleClickOpenAddDocumentModal = () => {
    setOpenAddDocumentModal(true);
  };
  const handleCloseAddDocumentModal = () => {
    setOpenAddDocumentModal(false);
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const [openModal, setOpenModal] = React.useState(false);

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const [current, setCurrent] = React.useState(0);
  const [stateName, setStateName] = useState("");
  const [show, setShow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showActivities, setShowActivities] = useState(false);
  const [showTasks, setShowTasks] = useState(false);
  const [showReports, setShowReports] = useState(false);
  const [showDiagram, setShowDiagram] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [disabledStatus, setDisabledStatus] = useState(false);
  const renderTasks = () => {
    setShowTasks(true);
    setShowActivities(false);
    setShowDiagram(false);
    setShowReports(false);
  };
  const renderActivities = () => {
    setShowActivities(true);
    setShowDiagram(false);
    setShowTasks(false);
    setShowReports(false);
  };
  const renderReports = () => {
    setShowReports(true);
    setShowActivities(false);
    setShowDiagram(false);
    setShowTasks(false);
  };

  //bmpn Modal
  const handleClickOpen = () => {
    setOpen(true);
    setShowDiagram(true);
    setShowActivities(false);
    setShowTasks(false);
    setShowReports(false);
  };

  const handleModalClose = () => {
    setOpen(false);
    setShowDiagram(false);
    setShowActivities(true);
  };

  const renderRow = () => {
    const createAPReportDocument = async () => {
      try {
        const resultAction = await dispatch(
          addAnnualProgramReportDocument({
            resource: "AnnualprogramReport",
            program_id: AnnualProgramReport && AnnualProgramReport.program,
            version: 1.1,
          })
        );
        unwrapResult(resultAction);
      } catch (err) {
        console.error("Failed to save the GAReportDocument: ", err);
      } finally {
        dispatch(
          fetchAnnualProgramReportDocuments(
            AnnualProgramReport && AnnualProgramReport.program
          )
        );
      }
    };

    const handleDeleteAPReportDocument = (id) => {
      dispatch(deleteAnnualProgramReportDocument(id))
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          // handle result here
          console.log("originalPromiseResult", originalPromiseResult);
        })
        .then(() => {
          // do additional work
          dispatch(
            fetchAnnualProgramReportDocuments(
              AnnualProgramReport && AnnualProgramReport.program
            )
          );
        })
        .catch((rejectedValueOrSerializedError) => {
          // handle error here
          console.log(
            "rejectedValueOrSerializedError",
            rejectedValueOrSerializedError
          );
        });
    };

    if (showActivities) {
      return (
        <div>
          {Activities.map((item) => (
            <div>
              <Divider
                type="horizontal"
                orientation="center"
                style={{ color: "gray" }}
              >
                {Moment(
                  item &&
                    item.changes &&
                    item.changes[0] &&
                    item.changes[0].date
                ).format("YYYY-MM-DD")}
              </Divider>

              <GridContainer xs={12} sm={12} md={12}>
                <GridItem key={Element.id} xs={12} sm={12} md={2}>
                  <Avatar
                    style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                  >
                    {item.avatar}
                  </Avatar>
                </GridItem>
                <GridItem key={Element.id} xs={12} sm={12} md={10}>
                  <p>
                    <strong>
                      {item &&
                        item.changes &&
                        item.changes[0] &&
                        item.changes[0].user_id}
                    </strong>
                  </p>

                  <strong> Field : {item && item.field}</strong>
                  <ul style={{ paddingLeft: "0px", marginLeft: "0px" }}>
                    <li>
                      {item &&
                        item.changes &&
                        item.changes[0] &&
                        item.changes[0].value1}
                      <ArrowRightOutlined style={{ fontSize: "12px" }} />
                      {item &&
                        item.changes &&
                        item.changes[0] &&
                        item.changes[0].value2}
                    </li>
                  </ul>
                </GridItem>
              </GridContainer>
            </div>
          ))}
        </div>
      );
    } else if (showTasks) {
      return <div>Tasks</div>;
    } else {
      return (
        <div>
          <Button color="primary" onClick={handleClickOpenAddDocumentModal}>
            New
          </Button>
          {annualProgramReportDocuments &&
            annualProgramReportDocuments.map((document) => (
              <Card style={{ backgroundColor: grayColor[8] }}>
                <GridContainer justify="space-between" alignItems="center">
                  <Link
                    to={`/citrine/reports/${type}/annualProgram/${id}/documents/${document.id}`}
                  >
                    <GridItem>
                      <h6> {document.name}</h6>
                      <br />
                      <h7>State : {document.state}</h7>
                      <br />
                      <h7>Created By: {document.creator_details.username}</h7>
                      <br />
                      <h7>Created On: {document.creation_date}</h7>
                      <br />
                      <>
                        Assigned To:{" "}
                        {/* {document.report_details.assign_to_details && document.report_details.assign_to_details[0].username} */}
                        {
                          document?.report_details?.assign_to_details[0]
                            ?.first_name
                        }{" "}
                        {
                          document?.report_details?.assign_to_details[0]
                            ?.last_name
                        }
                      </>
                      <br />
                      <h7>Version: {document.version} </h7>
                      <br />
                    </GridItem>
                  </Link>
                  <GridItem>
                    <Button
                      color="transparent"
                      simple
                      aria-label="Dashboard"
                      justIcon
                      onClick={() => {
                        handleDeleteAPReportDocument(document.id);
                      }}
                    >
                      <DeleteForeverIcon />
                    </Button>
                  </GridItem>
                </GridContainer>
              </Card>
            ))}
        </div>
      );
    }
  };

  const Activities = useSelector((state) => state.Activity.Activities);
  const AnnualProgramReport = useSelector(
    (state) => state.AnnualProgramReport.AnnualProgramReport
  );
  console.log("AnnualProgramReport", AnnualProgramReport);
  const annualProgramReportDocuments = useSelector(
    (state) => state.AnnualProgramReportDocument.annualProgramReportDocuments
  );
  console.log(annualProgramReportDocuments);

  useEffect(() => {
    setStateName(
      AnnualProgramReport &&
        AnnualProgramReport.state_object &&
        AnnualProgramReport.state_object
    );
  }, []);

  const Approve = () => {
    setShow(true);
    setStateName("Approved");
    localStorage.setItem("state", "Approved");
    //dispatch(fetchActivitiesProgramSpec(programId));
    try {
      let modifiedAnnualProgramReport = {
        due_date:
          AnnualProgramReport &&
          AnnualProgramReport.due_date &&
          AnnualProgramReport.due_date,
        state_object: "Approved",
      };
      const resultAction = dispatch(
        UpdateAnnualProgramReport({
          id: id,
          updatedAnnualProgramReport: { ...modifiedAnnualProgramReport },
        })
      );
      unwrapResult(resultAction);
      setStateName("Approved");
      dispatch(fetchAnnualProgramReports());
      setDisabledStatus(true);
    } catch (err) {
      console.log("Failed to save the CourseReport: ", err);
    } finally {
      dispatch(fetchAnnualProgramReports());
      //dispatch(fetchPrograms());
    }
  };
  const SendforApproval = () => {
    setShow(true);
    //dispatch(fetchActivitiesProgramSpec(programId));
    setStateName("Waiting for approval");
    localStorage.setItem("state", "Waiting for approval");
    setDisabledStatus(true);
    try {
      let modifiedAnnualProgramReport = {
        due_date:
          AnnualProgramReport &&
          AnnualProgramReport.due_date &&
          AnnualProgramReport.due_date,
        state_object: "Waiting for approval",
      };
      const resultAction = dispatch(
        UpdateAnnualProgramReport({
          id: id,
          updatedAnnualProgramReport: { ...modifiedAnnualProgramReport },
        })
      );
      unwrapResult(resultAction);
      setStateName("Waiting for approval");
      dispatch(fetchAnnualProgramReports());
      setDisabledStatus(true);
      //dispatch(fetchActivitiesProgramSpec(programId));
    } catch (err) {
      console.log("Failed to save the CourseReport: ", err);
    } finally {
      dispatch(fetchAnnualProgramReports());
      // dispatch(fetchActivitiesProgramSpec(programId));
      setDisabledStatus(true);
    }
  };
  const ResetTODraft = () => {
    setShow(true);
    setStateName("Created");
    localStorage.setItem("state", "Created");
    // dispatch(fetchActivitiesProgramSpec(programId));
    try {
      let modifiedAnnualProgramReport = {
        due_date:
          AnnualProgramReport &&
          AnnualProgramReport.due_date &&
          AnnualProgramReport.due_date,
        state_object: "Created",
      };
      const resultAction = dispatch(
        UpdateAnnualProgramReport({
          id: id,
          updatedAnnualProgramReport: { ...modifiedAnnualProgramReport },
        })
      );
      unwrapResult(resultAction);
      setStateName("Created");
      dispatch(fetchAnnualProgramReports());
      setDisabledStatus(true);
    } catch (err) {
      console.log("Failed to save the CourseReport: ", err);
    } finally {
      dispatch(fetchAnnualProgramReports());
      //dispatch(fetchPrograms());
    }
  };

  const Archive = () => {
    setShow(true);
    setStateName("Created");
    //dispatch(fetchActivitiesProgramSpec(programId));
    try {
      let modifiedAnnualProgramReport = {
        due_date:
          AnnualProgramReport &&
          AnnualProgramReport.due_date &&
          AnnualProgramReport.due_date,
        state_object: "Archived",
      };
      const resultAction = dispatch(
        UpdateAnnualProgramReport({
          id: id,
          updatedAnnualProgramReport: { ...modifiedAnnualProgramReport },
        })
      );
      unwrapResult(resultAction);
      setStateName("Archived");
      dispatch(fetchAnnualProgramReports());
      setDisabledStatus(true);
    } catch (err) {
      console.log("Failed to save the CourseReport: ", err);
    } finally {
      dispatch(fetchAnnualProgramReports());
      //dispatch(fetchPrograms());
    }
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let content;
  {
    // state_actions &&
    //   state_actions

    AnnualProgramReport &&
      AnnualProgramReport.states_actions &&
      AnnualProgramReport.states_actions.map((item) => {
        if (stateName === "Created" && item.state === "Created") {
          content = (
            <div
              style={{
                height: "100%",
                backgroundColor: "white",
                width: "100%",
                borderRadius: "3px",
                border: "1px solid lightgrey",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "10px",
                }}
              >
                {item &&
                  item.actions &&
                  item.actions.map((action) => {
                    if (
                      action &&
                      action.name.toLowerCase().includes("send for approval")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Send for approval")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("generate actions")
                    ) {
                      return (
                        <GenerateAnnualProgramActionsModal
                          AnnualProgramReport={AnnualProgramReport}
                          handleDropDownButtonClose={handleClose}
                        />
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("approve")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Approve()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Approve")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("reset tO draft")
                    ) {
                      return (
                        <Button
                          style={{ backgroundColor: "grey" }}
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => ResetTODraft()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Reset tO draft")}
                          </span>
                        </Button>
                      );
                    }
                  })}

                <ol
                  class="progress-bar"
                  style={{ width: "550px", height: "40px" }}
                >
                  {AnnualProgramReport &&
                    AnnualProgramReport.states_actions &&
                    AnnualProgramReport.states_actions.map((item) => {
                      return item.state === "Created" ? (
                        <li
                          class="progress-bar__steps current"
                          style={{
                            width: "auto",
                            paddingTop: "10px",
                            textAlign: "center",
                          }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      ) : (
                        <li
                          class="progress-bar__steps not-current"
                          style={{ width: "auto", paddingTop: "10px" }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      );
                    })}
                </ol>
              </div>
            </div>
          );
        } else if (
          stateName &&
          stateName.toLowerCase().includes("waiting for approval") &&
          item.state &&
          item.state.toLowerCase().includes("waiting for approval")
        ) {
          content = (
            <div
              style={{
                height: "100%",
                backgroundColor: "white",
                width: "100%",
                borderRadius: "3px",
                border: "1px solid lightgrey",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "10px",
                }}
              >
                {item &&
                  item.actions &&
                  item.actions.map((action) => {
                    if (
                      action &&
                      action.name.toLowerCase().includes("send for approval")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Send for approval")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("generate actions")
                    ) {
                      return (
                        <GenerateAnnualProgramActionsModal
                          AnnualProgramReport={AnnualProgramReport}
                          handleDropDownButtonClose={handleClose}
                        />
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("approve")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Approve()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Approve")}
                          </span>
                        </Button>
                      );
                    } else {
                      return (
                        <Button
                          style={{ backgroundColor: "grey" }}
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => ResetTODraft()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Reset tO draft")}
                          </span>
                        </Button>
                      );
                    }
                  })}

                <ol
                  class="progress-bar"
                  style={{ width: "550px", height: "40px" }}
                >
                  {AnnualProgramReport &&
                    AnnualProgramReport.states_actions &&
                    AnnualProgramReport.states_actions.map((item) => {
                      return item.state &&
                        item.state
                          .toLowerCase()
                          .includes("waiting for approval") ? (
                        <li
                          class="progress-bar__steps current"
                          style={{
                            width: "auto",
                            paddingTop: "10px",
                            textAlign: "center",
                          }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      ) : (
                        <li
                          class="progress-bar__steps not-current"
                          style={{ width: "auto", paddingTop: "10px" }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      );
                    })}
                </ol>
              </div>
            </div>
          );
        } else if (stateName === "Approved" && item.state === "Approved") {
          content = (
            <div
              style={{
                height: "100%",
                backgroundColor: "white",
                width: "100%",
                borderRadius: "3px",
                border: "1px solid lightgrey",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "10px",
                }}
              >
                {item &&
                  item.actions &&
                  item.actions.map((action) => {
                    if (
                      action &&
                      action.name.toLowerCase().includes("send for approval")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Send for approval")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("generate actions")
                    ) {
                      return (
                        <GenerateAnnualProgramActionsModal
                          AnnualProgramReport={AnnualProgramReport}
                          handleDropDownButtonClose={handleClose}
                        />
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("approve")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Approve()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Approve")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("archive")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Archive()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Archive")}
                          </span>
                        </Button>
                      );
                    } else {
                      return (
                        <Button
                          style={{ backgroundColor: "grey" }}
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => ResetTODraft()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Reset tO draft")}
                          </span>
                        </Button>
                      );
                    }
                  })}

                <ol
                  class="progress-bar"
                  style={{ width: "550px", height: "40px" }}
                >
                  {AnnualProgramReport &&
                    AnnualProgramReport.states_actions &&
                    AnnualProgramReport.states_actions.map((item) => {
                      return item.state === "Approved" ? (
                        <li
                          class="progress-bar__steps current"
                          style={{
                            width: "auto",
                            paddingTop: "10px",
                            textAlign: "center",
                          }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      ) : (
                        <li
                          class="progress-bar__steps not-current"
                          style={{ width: "auto", paddingTop: "10px" }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      );
                    })}
                </ol>
              </div>
            </div>
          );
        } else if (stateName === "Archived" && item.state === "Archived") {
          content = (
            <div
              style={{
                height: "100%",
                backgroundColor: "white",
                width: "100%",
                borderRadius: "3px",
                border: "1px solid lightgrey",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "10px",
                }}
              >
                {item &&
                  item.actions &&
                  item.actions.map((action) => {
                    if (
                      action &&
                      action.name.toLowerCase().includes("send for approval")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Send for approval")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("generate actions")
                    ) {
                      return (
                        <GenerateAnnualProgramActionsModal
                          AnnualProgramReport={AnnualProgramReport}
                          handleDropDownButtonClose={handleClose}
                        />
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("approve")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Approve()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Approve")}
                          </span>
                        </Button>
                      );
                    } else {
                      return (
                        <Button
                          style={{ backgroundColor: "grey" }}
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => ResetTODraft()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Reset tO draft")}
                          </span>
                        </Button>
                      );
                    }
                  })}

                <ol
                  class="progress-bar"
                  style={{ width: "550px", height: "40px" }}
                >
                  {AnnualProgramReport &&
                    AnnualProgramReport.states_actions &&
                    AnnualProgramReport.states_actions.map((item) => {
                      return item.state === "Archived" ? (
                        <li
                          class="progress-bar__steps current"
                          style={{
                            width: "auto",
                            paddingTop: "10px",
                            textAlign: "center",
                          }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      ) : (
                        <li
                          class="progress-bar__steps not-current"
                          style={{ width: "auto", paddingTop: "10px" }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      );
                    })}
                </ol>
              </div>
            </div>
          );
        }
      });
  }
  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">">
          {/* <Breadcrumb.Item>
            <Link to={`/citrine/programs`}> {t("Programs List")}</Link>
          </Breadcrumb.Item> */}
          <Breadcrumb.Item>
            <Link to={`/citrine/reports/${type}/annualProgram`}>
              {t("Annual Program Reports List")}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              style={{ color: "orange" }}
              to={`/citrine/reports/${type}/annualProgram/${id}`}
            >
              {t("Annual Program Report Details")}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>

      <GridContainer xs={12} sm={12} md={12}>
        <GridItem key={Element.id} xs={12} sm={12} md={9}>
          <GridContainer xs={12} sm={12} md={12}>
            {content}
          </GridContainer>

          <GridContainer style={{ border: "none", marginTop: "10px" }}>
            <div class="scrollable">
              <div
                style={{
                  padding: "10px",
                  borderRadius: "0px",
                  color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
                  background: whiteColor,
                  width: "100%",
                  boxShadow:
                    "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "0",
                  wordWrap: "break-word",
                  fontSize: ".875rem",
                  // borderRight: "1px solid lightgray"
                }}
              >
                <Tabs
                  onChange={(activeKey) => {
                    setActiveParentTabKey(activeKey);
                    console.log(activeKey);
                  }}
                  type="card"
                >
                  <TabPane tab={t("Identification")} key="1">
                    <Identification AnnualProgramReport={AnnualProgramReport} />
                  </TabPane>

                  <TabPane tab={t("Previous Action Plan")} key="2">
                    <PreviousActionPlan
                      resource_id={AnnualProgramReport?.id}
                      resource="annualProgram"
                    />
                  </TabPane>

                  <TabPane tab={t("Program Statistics")} key="3">
                    <ProgramStatistics
                      AnnualProgramReport={AnnualProgramReport}
                      activeParentTabKey={activeParentTabKey}
                    />
                  </TabPane>

                  <TabPane tab={t("PLOs Assessment")} key="4">
                    <PLOsAssessment
                      AnnualProgramReport={AnnualProgramReport}
                      activeParentTabKey={activeParentTabKey}
                    />
                  </TabPane>
                  <TabPane tab={t("Summary of Course Reports")} key="5">
                    <SummaryOfCourseReports
                      AnnualProgramReport={AnnualProgramReport}
                      activeParentTabKey={activeParentTabKey}
                    />
                  </TabPane>
                  <TabPane tab={t("Program Activities")} key="6">
                    <ProgramActivities
                      AnnualProgramReport={AnnualProgramReport}
                      activeParentTabKey={activeParentTabKey}
                    />
                  </TabPane>

                  <TabPane tab={t("Program Evaluation")} key="7">
                    <ProgramEvaluationPage
                      AnnualProgramReport={AnnualProgramReport}
                      activeParentTabKey={activeParentTabKey}
                    />
                  </TabPane>

                  <TabPane tab={t("Difficulties and Challenges")} key="8">
                    <DifficultiesAndChallenges
                      AnnualProgramReport={AnnualProgramReport}
                    />
                  </TabPane>

                  <TabPane tab={t("Program Improvement Plan")} key="9">
                    <ProgramImprovementPlan
                      AnnualProgramReport={AnnualProgramReport}
                    />
                  </TabPane>
                  <TabPane tab={t("Dynamic section")} key="10">
                    <HTMLEditor />
                  </TabPane>
                </Tabs>
                <ErrorModal
                  open={openErrorModal}
                  handleClose={handleCloseErrorModal}
                  handledError={handledError}
                />
              </div>
            </div>
          </GridContainer>
        </GridItem>

        <GridItem key={Element.id} xs={12} sm={12} md={3}>
          <div
            style={{
              padding: "10px",
              borderRadius: "0px",
              color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
              background: whiteColor,
              width: "100%",
              boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              minWidth: "0",
              wordWrap: "break-word",
              fontSize: ".875rem",
              height: 570,
              border: "1px solid lightgray",
            }}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer justify="space-around">
                  {/* <GridItem>
                    <Button
                      color="transparent"
                      simple
                      aria-label="Dashboard"
                      justIcon
                      onClick={renderActivities}
                    >
                      <FaHistory
                        color={showActivities ? primaryColor[0] : "black"}
                      />
                    </Button>
                  </GridItem>
                  <GridItem>
                    <Button
                      color="transparent"
                      simple
                      aria-label="Dashboard"
                      justIcon
                      onClick={renderTasks}
                    >
                      <FaTasks color={showTasks ? primaryColor[0] : "black"} />
                    </Button>
                  </GridItem>
                  <GridItem>
                    <Button
                      color="transparent"
                      simple
                      aria-label="Dashboard"
                      justIcon
                      onClick={(event) => {
                        handleClickOpen(event);
                      }}
                    >
                      <FaProjectDiagram
                        color={showDiagram ? primaryColor[0] : "black"}
                      />
                    </Button>
                  </GridItem> */}
                  <GridItem>
                    <Button
                      color="transparent"
                      simple
                      aria-label="Dashboard"
                      justIcon
                      onClick={renderReports}
                    >
                      <GoReport
                        color={showReports ? primaryColor[0] : "black"}
                      />
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <div
                className={classes1.root}
                style={{
                  height: "470px",
                  paddingBottom: "0px",
                  overflowY: "auto",
                  maxHeight: "500px",
                  width: "100%",
                }}
              >
                <GridItem xs={12} sm={12} md={12}>
                  <div>{renderRow()}</div>
                </GridItem>
              </div>
            </GridContainer>
          </div>
        </GridItem>
      </GridContainer>
      <AddDocument
        programId={AnnualProgramReport && AnnualProgramReport.program}
        open={openAddDocumentModal}
        handleClickOpen={handleClickOpenAddDocumentModal}
        handleClose={handleCloseAddDocumentModal}
      />
    </div>
  );
}
