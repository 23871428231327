// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { unwrapResult } from "@reduxjs/toolkit";
import { Avatar, Breadcrumb, Divider, notification, Tabs } from "antd";
import "antd/dist/antd.css";
// core components
import {
  blackColor,
  cardTitle,
  grayColor,
  hexToRgb,
  primaryColor,
  whiteColor,
} from "assets/jss/material-dashboard-pro-react.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import "bpmn-js/dist/assets/diagram-js.css";
import Card from "components/Card/Card.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
// core components
import { ArrowRightOutlined } from "@ant-design/icons";
import GridItem from "components/Grid/GridItem.js";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GoReport } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchActivities } from "../../../../reduxToolkit/features/Activitiy/ActivitiesSlice";
import {
  CourseReportUpdate,
  fetchCourseReportById,
  fetchCourseReports,
} from "../../../../reduxToolkit/features/CourseImprovement/courseImprovementSlice";
import {
  addNewCourseReportDocument,
  deleteCourseReportDocument,
  fetchCourseReportDocumentsByCourseId,
} from "../../../../reduxToolkit/features/CourseReportDocument/CourseReportDocumentSlice";
import DropDownDocumentCourseReport from "../../../Components/DropDownDocumentCourseReport";
import ErrorModal from "../../../Components/ErrorModal.jsx";
import GenerateActionsCourseModal from "../../../Components/GenerateActionsCourseModal";
import PreviousActionPlan from "../../../Components/PreviousActionPlan";
import CourseDelivery from "./CourseDelivery/CourseDelivery";
import CourseReportRecommendation from "./CourseImprovementPlan/CourseReportRecommendation";
import CourseLearningOutcomes from "./CourseLearningOutcomes/CourseLearningOutcomes";
import StudentsEvaluation from "./CourseQualityEvaluation/StudentsEvaluation";
import DifficultiesAndChallenges from "./DifficultiesAndChallenges/DifficultiesAndChallenges";
import Identification from "./Identification";
import "../improvement.css";
import StudentResultsPage from "./Student Results/StudentResultsPage";
import CreateCourseReportDocument from "../../../Components/CreateCourseReportDocument";
import HTMLEditor from "components/HTMLEditor/HTMLEditor";

const { TabPane } = Tabs;

const useStyles = makeStyles(styles);

export default function CourseImprovement({ match }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { id, type } = match.params;

  const dispatch = useDispatch();

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [current, setCurrent] = useState(0);
  const [stateName, setStateName] = useState("");
  const [show, setShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showActivities, setShowActivities] = useState(false);
  const [showTasks, setShowTasks] = useState(false);
  const [showReports, setShowReports] = useState(true);
  const [showDiagram, setShowDiagram] = useState(false);
  const [open, setOpen] = useState(false);
  const [disabledStatus, setDisabledStatus] = useState(false);

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const renderReports = () => {
    setShowReports(true);
    setShowDiagram(false);
    setShowTasks(false);
  };

  const handleModalClose = () => {
    setOpen(false);
    setShowDiagram(false);
    // setShowActivities(true);
  };

  const renderRow = () => {
    const handleDeleteCourseReportDocument = (id) => {
      dispatch(deleteCourseReportDocument(id))
        .then(unwrapResult)
        .then((originalPromiseResult) => {})
        .then(() => {
          dispatch(
            fetchCourseReportDocumentsByCourseId(
              CourseReport && CourseReport.course[0].id
            )
          );
        })
        .catch((rejectedValueOrSerializedError) => {
          console.log(
            "rejectedValueOrSerializedError",
            rejectedValueOrSerializedError
          );
        });
    };
    if (showActivities) {
      return (
        <div>
          {Activities.map((item) => (
            <div>
              <Divider
                type="horizontal"
                orientation="center"
                style={{ color: "gray" }}
              >
                {Moment(
                  item &&
                    item.changes &&
                    item.changes[0] &&
                    item.changes[0].date
                ).format("YYYY-MM-DD")}
              </Divider>
              <div
                style={{
                  backgroundColor: "#edeff1",
                  borderBottom: "1px solid #cfd5db",
                }}
              >
                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem key={Element.id} xs={12} sm={12} md={2}>
                    <Avatar
                      style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                    >
                      {item.avatar}
                    </Avatar>
                  </GridItem>
                  <GridItem key={Element.id} xs={12} sm={12} md={10}>
                    <p>
                      <strong>{item && item.Field}</strong>
                    </p>
                    <ul style={{ paddingLeft: "0px", marginLeft: "0px" }}>
                      <li>
                        {item && item.value1}
                        <ArrowRightOutlined style={{ fontSize: "12px" }} />
                        {item && item.value2}
                      </li>
                    </ul>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div>
          <div style={{ zIndex: 1000 }}>
            {/*DropDownDocumentCourseReport*/}
            <CreateCourseReportDocument
              title={"Create Course Report"}
              CourseReportId={id}
            />
          </div>
          {courseReportDocuments &&
            courseReportDocuments.map((document) => (
              <Card style={{ backgroundColor: grayColor[8] }}>
                <GridContainer justify="space-between" alignItems="center">
                  <Link
                    to={`/citrine/reports/${type}/course/${id}/documents/${document.id}`}
                  >
                    <GridItem>
                      <h6> {document.name}</h6>
                      <br />
                      <h7>State : {document.state}</h7>
                      <br />
                      <h7>Created By: {document.creator_details.username}</h7>
                      <br />
                      <h7>Created On: {document.creation_date}</h7>
                      <br />
                      <h7>
                        Assigned To:{" "}
                        {document.report_details.assign_to_details &&
                          document.report_details.assign_to_details[0]
                            ?.username}
                      </h7>
                      <br />
                      <h7>Version: {document.version} </h7>
                      <br />
                    </GridItem>
                  </Link>
                  <GridItem>
                    <Button
                      color="transparent"
                      simple
                      aria-label="Dashboard"
                      justIcon
                      onClick={() => {
                        handleDeleteCourseReportDocument(document.id);
                      }}
                    >
                      <DeleteForeverIcon />
                    </Button>
                  </GridItem>
                </GridContainer>
              </Card>
            ))}
        </div>
      );
    }
  };

  useEffect(() => {
    dispatch(fetchCourseReportById(id))
      .then(unwrapResult)
      .then((CourseReport) => {
        setStateName(
          CourseReport && CourseReport.state_object && CourseReport.state_object
        );
        dispatch(
          fetchCourseReportDocumentsByCourseId(
            CourseReport && CourseReport.course[0].id
          )
        );
      });
    dispatch(fetchActivities());
  }, []);

  const CourseReport = useSelector((state) => state.CourseReport.CourseReport);

  useEffect(() => {
    setStateName(
      CourseReport && CourseReport.state_object && CourseReport.state_object
    );
    localStorage.setItem(
      "CourseReport_State",
      CourseReport && CourseReport.state_object && CourseReport.state_object
    );
  }, []);

  const Activities = useSelector((state) => state.Activity.Activities);
  const courseReportDocuments = useSelector(
    (state) => state.courseReportDocument.courseReportDocuments
  );

  const Approve = () => {
    setShow(true);
    setStateName("Approved");
    localStorage.setItem("CourseReport_State", "Approved");
    try {
      let modifiedCourseReport = {
        due_date:
          CourseReport && CourseReport.due_date && CourseReport.due_date,
        state_object: "Approved",
      };
      const resultAction = dispatch(
        CourseReportUpdate({
          id: id,
          updatedCourseReport: { ...modifiedCourseReport },
        }),
        dispatch(fetchCourseReports())
      );
      unwrapResult(resultAction);
      setStateName("Approved");
      dispatch(fetchCourseReports());
      setDisabledStatus(true);
    } catch (err) {
      console.log("Failed to save the CourseReport: ", err);
    } finally {
      dispatch(fetchCourseReports());
    }
  };

  const SendforApproval = () => {
    setShow(true);
    setStateName("Waiting for approval");
    localStorage.setItem("CourseReport_State", "Waiting for approval");
    try {
      let modifiedCourseReport = {
        due_date:
          CourseReport && CourseReport.due_date && CourseReport.due_date,
        state_object: "Waiting for approval",
      };
      const resultAction = dispatch(
        CourseReportUpdate({
          id: id,
          updatedCourseReport: { ...modifiedCourseReport },
        }),
        dispatch(fetchCourseReports())
      );
      unwrapResult(resultAction);
      setStateName("Waiting for approval");
      dispatch(fetchCourseReports());
      setDisabledStatus(true);
    } catch (err) {
      console.log("Failed to save the CourseReport: ", err);
    } finally {
      dispatch(fetchCourseReports());
    }
  };

  const ResetTODraft = () => {
    setStateName("Created");
    localStorage.setItem("CourseReport_State", "Created");
    try {
      let modifiedCourseReport = {
        due_date:
          CourseReport && CourseReport.due_date && CourseReport.due_date,
        state_object: "Created",
      };
      const resultAction = dispatch(
        CourseReportUpdate({
          id: id,
          updatedCourseReport: { ...modifiedCourseReport },
        }),
        dispatch(fetchCourseReports())
      );
      unwrapResult(resultAction);
      setStateName("Created");
      dispatch(fetchCourseReports());
      setDisabledStatus(true);
    } catch (err) {
      console.log("Failed to save the CourseReport: ", err);
    } finally {
      dispatch(fetchCourseReports());
    }
  };

  const Archive = () => {
    setShow(true);
    setStateName("Archived");
    localStorage.setItem("CourseReport_State", "Archived");
    try {
      let modifiedCourseReport = {
        due_date:
          CourseReport && CourseReport.due_date && CourseReport.due_date,
        state_object: "Archived",
      };
      const resultAction = dispatch(
        CourseReportUpdate({
          id: id,
          updatedCourseReport: { ...modifiedCourseReport },
        }),
        dispatch(fetchCourseReports())
      );
      unwrapResult(resultAction);
      setStateName("Archived");
      dispatch(fetchCourseReports());
      setDisabledStatus(true);
    } catch (err) {
      console.log("Failed to save the CourseReport: ", err);
    } finally {
      dispatch(fetchCourseReports());
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let content;
  {
    CourseReport &&
      CourseReport.states_actions &&
      CourseReport.states_actions.map((item) => {
        if (stateName === "Created" && item.state === "Created") {
          content = (
            <div
              style={{
                height: "100%",
                backgroundColor: "white",
                width: "100%",
                borderRadius: "3px",
                border: "1px solid lightgrey",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "10px",
                }}
              >
                {item &&
                  item.actions &&
                  item.actions.map((action) => {
                    if (
                      action &&
                      action.name.toLowerCase().includes("send for approval")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Send for approval")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("generate actions")
                    ) {
                      return (
                        <GenerateActionsCourseModal
                          CourseReport={CourseReport}
                          handleDropDownButtonClose={handleClose}
                        />
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("approve")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Approve()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Approve")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("reset tO draft")
                    ) {
                      return (
                        <Button
                          style={{ backgroundColor: "grey" }}
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => ResetTODraft()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Reset tO draft")}
                          </span>
                        </Button>
                      );
                    }
                  })}
                <ol
                  class="progress-bar"
                  style={{ width: "550px", height: "40px" }}
                >
                  {CourseReport &&
                    CourseReport.states_actions &&
                    CourseReport.states_actions.map((item) => {
                      return item.state === "Created" ? (
                        <li
                          class="progress-bar__steps current"
                          style={{
                            width: "auto",
                            paddingTop: "10px",
                            textAlign: "center",
                          }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      ) : (
                        <li
                          class="progress-bar__steps not-current"
                          style={{ width: "auto", paddingTop: "10px" }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      );
                    })}
                </ol>
              </div>
            </div>
          );
        } else if (
          stateName &&
          stateName.toLowerCase().includes("waiting for approval") &&
          item.state &&
          item.state.toLowerCase().includes("waiting for approval")
        ) {
          content = (
            <div
              style={{
                height: "100%",
                backgroundColor: "white",
                width: "100%",
                borderRadius: "3px",
                border: "1px solid lightgrey",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "10px",
                }}
              >
                {item &&
                  item.actions &&
                  item.actions.map((action) => {
                    if (
                      action &&
                      action.name.toLowerCase().includes("send for approval")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Send for approval")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("generate actions")
                    ) {
                      return (
                        <GenerateActionsCourseModal
                          CourseReport={CourseReport}
                          handleDropDownButtonClose={handleClose}
                        />
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("approve")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Approve()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Approve")}
                          </span>
                        </Button>
                      );
                    } else {
                      return (
                        <Button
                          style={{ backgroundColor: "grey" }}
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => ResetTODraft()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Reset tO draft")}
                          </span>
                        </Button>
                      );
                    }
                  })}
                <ol
                  class="progress-bar"
                  style={{ width: "550px", height: "40px" }}
                >
                  {CourseReport &&
                    CourseReport.states_actions &&
                    CourseReport.states_actions.map((item) => {
                      return item.state &&
                        item.state
                          .toLowerCase()
                          .includes("waiting for approval") ? (
                        <li
                          class="progress-bar__steps current"
                          style={{
                            width: "auto",
                            paddingTop: "10px",
                            textAlign: "center",
                          }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      ) : (
                        <li
                          class="progress-bar__steps not-current"
                          style={{ width: "auto", paddingTop: "10px" }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      );
                    })}
                </ol>
              </div>
            </div>
          );
        } else if (stateName === "Approved" && item.state === "Approved") {
          content = (
            <div
              style={{
                height: "100%",
                backgroundColor: "white",
                width: "100%",
                borderRadius: "3px",
                border: "1px solid lightgrey",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "10px",
                }}
              >
                {item &&
                  item.actions &&
                  item.actions.map((action) => {
                    if (
                      action &&
                      action.name.toLowerCase().includes("send for approval")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Send for approval")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("generate actions")
                    ) {
                      return (
                        <GenerateActionsCourseModal
                          CourseReport={CourseReport}
                          handleDropDownButtonClose={handleClose}
                        />
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("approve")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Approve()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Approve")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("archive")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Archive()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Archive")}
                          </span>
                        </Button>
                      );
                    } else {
                      return (
                        <Button
                          style={{ backgroundColor: "grey" }}
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => ResetTODraft()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Reset tO draft")}
                          </span>
                        </Button>
                      );
                    }
                  })}

                <ol
                  class="progress-bar"
                  style={{ width: "550px", height: "40px" }}
                >
                  {CourseReport &&
                    CourseReport.states_actions &&
                    CourseReport.states_actions.map((item) => {
                      return item.state === "Approved" ? (
                        <li
                          class="progress-bar__steps current"
                          style={{
                            width: "auto",
                            paddingTop: "10px",
                            textAlign: "center",
                          }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      ) : (
                        <li
                          class="progress-bar__steps not-current"
                          style={{ width: "auto", paddingTop: "10px" }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      );
                    })}
                </ol>
              </div>
            </div>
          );
        } else if (stateName === "Archived" && item.state === "Archived") {
          content = (
            <div
              style={{
                height: "100%",
                backgroundColor: "white",
                width: "100%",
                borderRadius: "3px",
                border: "1px solid lightgrey",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "10px",
                }}
              >
                {item &&
                  item.actions &&
                  item.actions.map((action) => {
                    if (
                      action &&
                      action.name.toLowerCase().includes("send for approval")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => SendforApproval()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Send for approval")}
                          </span>
                        </Button>
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("generate actions")
                    ) {
                      return (
                        <GenerateActionsCourseModal
                          CourseReport={CourseReport}
                          handleDropDownButtonClose={handleClose}
                        />
                      );
                    } else if (
                      action &&
                      action.name.toLowerCase().includes("approve")
                    ) {
                      return (
                        <Button
                          color="primary"
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => Approve()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Approve")}
                          </span>
                        </Button>
                      );
                    } else {
                      return (
                        <Button
                          style={{ backgroundColor: "grey" }}
                          size="sm"
                          className={classes.marginRight}
                          onClick={() => ResetTODraft()}
                        >
                          <span style={{ color: "white", fontSize: "11px" }}>
                            {t("Reset tO draft")}
                          </span>
                        </Button>
                      );
                    }
                  })}
                <ol
                  class="progress-bar"
                  style={{ width: "550px", height: "40px" }}
                >
                  {CourseReport &&
                    CourseReport.states_actions &&
                    CourseReport.states_actions.map((item) => {
                      return item.state === "Archived" ? (
                        <li
                          class="progress-bar__steps current"
                          style={{
                            width: "auto",
                            paddingTop: "10px",
                            textAlign: "center",
                          }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      ) : (
                        <li
                          class="progress-bar__steps not-current"
                          style={{ width: "auto", paddingTop: "10px" }}
                        >
                          <span class="progress-bar__steps--text">
                            {item.state}
                          </span>
                        </li>
                      );
                    })}
                </ol>
              </div>
            </div>
          );
        }
      });
  }

  return (
    <div>
      <GridItem>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={`/citrine/reports/${type}/course/`}>
              {t("Course Report List")}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link
              style={{ color: "orange" }}
              to={`/citrine/reports/${type}/course/${id}`}
            >
              {t("Course Report Details")}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </GridItem>
      <div>
        <GridContainer xs={12} sm={12} md={12}>
          <GridItem key={Element.id} xs={12} sm={12} md={9}>
            <GridContainer xs={12} sm={12} md={12}>
              {content}
            </GridContainer>
            <GridContainer style={{ border: "none", marginTop: "10px" }}>
              <div class="scrollable">
                <div
                  style={{
                    padding: "10px",
                    borderRadius: "0px",
                    color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
                    background: whiteColor,
                    width: "100%",
                    boxShadow:
                      "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    minWidth: "0",
                    wordWrap: "break-word",
                    fontSize: ".875rem",
                  }}
                >
                  <Identification
                    CourseReport={CourseReport}
                    idCourse={
                      CourseReport &&
                      CourseReport.course &&
                      CourseReport.course[0] &&
                      CourseReport.course[0].id
                    }
                  />
                  <Tabs type="card">
                    <TabPane tab={t("Course Delivery")} key="1">
                      <CourseDelivery
                        idCourse={
                          CourseReport &&
                          CourseReport.course &&
                          CourseReport.course[0] &&
                          CourseReport.course[0].id
                        }
                        type={
                          CourseReport &&
                          CourseReport.course &&
                          CourseReport.course[0] &&
                          CourseReport.course[0].type
                        }
                        CourseReport={CourseReport && CourseReport}
                      />
                    </TabPane>
                    <TabPane tab={t("Student Results")} key="2">
                      <StudentResultsPage
                        idCourse={
                          CourseReport &&
                          CourseReport.course &&
                          CourseReport.course[0] &&
                          CourseReport.course[0].id
                        }
                      />
                    </TabPane>
                    <TabPane tab={t("CLOs")} key="3">
                      <CourseLearningOutcomes
                        idCourse={
                          CourseReport &&
                          CourseReport.course &&
                          CourseReport.course[0] &&
                          CourseReport.course[0].id
                        }
                      />
                    </TabPane>
                    <TabPane tab={t("Quality Evaluation")} key="4">
                      <StudentsEvaluation
                        resource="course"
                        type="ces"
                        resource_id={
                          CourseReport &&
                          CourseReport.course &&
                          CourseReport.course[0] &&
                          CourseReport.course[0].id
                        }
                      />
                    </TabPane>
                    <TabPane tab={t("Difficulties And Challenges")} key="5">
                      <DifficultiesAndChallenges
                        idCourse={
                          CourseReport &&
                          CourseReport.course &&
                          CourseReport.course[0] &&
                          CourseReport.course[0].id
                        }
                        type={
                          CourseReport &&
                          CourseReport.course &&
                          CourseReport.course[0] &&
                          CourseReport.course[0].type
                        }
                      />
                    </TabPane>
                    <TabPane tab={t("Previous Action Plan")} key="6">
                      <PreviousActionPlan
                        resource_id={
                          CourseReport && CourseReport && CourseReport.id
                        }
                        resource="course"
                      />
                    </TabPane>
                    <TabPane tab={t("Action Plan")} key="7">
                      <CourseReportRecommendation
                        course_id={
                          CourseReport &&
                          CourseReport.course &&
                          CourseReport.course[0] &&
                          CourseReport.course[0].id
                        }
                        CourseReport={CourseReport}
                      />
                    </TabPane>
                    <TabPane tab={t("Dynamic section")} key="8">
                      <HTMLEditor />
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </GridContainer>
            <div
              className={open ? "modal display-block" : "modal display-none"}
              onClick={(event) => {
                handleModalClose(event);
              }}
            >
              <section id="container" className="modal-main">
                <CloseIcon
                  onClick={(event) => {
                    handleModalClose(event);
                  }}
                  style={{
                    height: "20px",
                    fontSize: "26px",
                    margin: "10px",
                  }}
                  id="close"
                />
              </section>
            </div>
          </GridItem>
          <GridItem key={Element.id} xs={12} sm={12} md={3}>
            <div
              style={{
                padding: "10px",
                borderRadius: "0px",
                color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
                background: whiteColor,
                width: "100%",
                boxShadow:
                  "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                minWidth: "0",
                wordWrap: "break-word",
                fontSize: ".875rem",
                height: 615,
                border: "1px solid lightgray",
              }}
            >
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer justify="space-around">
                    <GridItem>
                      <Button
                        color="transparent"
                        simple
                        aria-label="Dashboard"
                        justIcon
                        onClick={renderReports}
                      >
                        <GoReport
                          color={showReports ? primaryColor[0] : "black"}
                        />
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div class="scrollable">
                    <div
                      style={{
                        padding: "10px",
                        borderRadius: "0px",
                        color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
                        width: "100%",
                        boxShadow:
                          "0 1px 4px 0 rgba(" +
                          hexToRgb(blackColor) +
                          ", 0.14)",
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        minWidth: "0",
                        wordWrap: "break-word",
                        fontSize: ".875rem",
                        height: "68vh",
                      }}
                    >
                      <GridContainer> {renderRow()}</GridContainer>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </GridItem>
        </GridContainer>
        <ErrorModal
          open={openErrorModal}
          handleClose={handleCloseErrorModal}
          handledError={handledError}
        />
      </div>
    </div>
  );
}
