import React, { useEffect, useState } from "react";
import cx from "classnames";
import { Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import { PrivateRoute } from "../components/privateRoutes";

import { getCurrentLanguage } from '../reduxToolkit/features/language/LanguageSlice';


import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import rtlstyles from "assets/jss/material-dashboard-pro-react/layouts/rtlStyle.js";

var ps;


export default function Dashboard(props) {
  const { ...rest } = props;
  const dispatch = useDispatch();
  const mainPanel = React.createRef();


  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [image, setImage] = React.useState(require("assets/img/sidebar-2.jpg"));
  const [color, setColor] = React.useState("blue");
  const [bgColor, setBgColor] = React.useState("black");
  // const [hasImage, setHasImage] = React.useState(true);
  const [logo, setLogo] = React.useState(require("assets/img/logo-white.png"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/citrine/full-screen-maps";
  };
  const getActiveRoute = routes => {
    let activeRoute = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/citrine") {
        return (
          <PrivateRoute
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };


  useEffect(() => {
    dispatch(getCurrentLanguage());
  }, []);
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, []);

  const languages = useSelector((state) => state.language.languages);
  const currentUser = useSelector((state) => state.user.currentUser);
  const item = useSelector((state) => state.language.currentLanguage);

  let menus = [];
  if (currentUser !== null) {
    menus = currentUser.menus
  }
  let filtredRoutes = [];
  menus.map(menu => {
    routes.map(r => {
      if (menu.name === r.name) {
        filtredRoutes.push(r);
      }
    })
  });


  // styles

  const useStyles =
    item && languages && languages[item] && languages[item].rtl
      ? makeStyles(rtlstyles)
      : makeStyles(styles);

  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
    });

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={filtredRoutes}
        logoText={"CARNELIAN"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        rtlActive={item && languages[item] && languages[item].rtl}

        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(filtredRoutes)}
          handleDrawerToggle={handleDrawerToggle}
          rtlActive={item && languages[item] && languages[item].rtl}
          {...rest}
        />
        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
              <Switch>
                {getRoutes(filtredRoutes)}
                {/* <Redirect from="/admin" to="/admin/dashboard" /> */}
              </Switch>
            </div>
          </div>
        ) : (
          <div className={classes.map}>
            <Switch>
              {getRoutes(filtredRoutes)}
              {/* <Redirect from="/admin" to="/admin/dashboard" /> */}
            </Switch>
          </div>
        )}
        {getRoute() ? <Footer fluid /> : null}
      </div>
    </div>
  );
}
