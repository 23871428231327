import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import { Form, notification, Select } from "antd";
// import Button from "components/CustomButtons/Button.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getProgramSurveyTemplates } from "reduxToolkit/features/Survey/SurveySlice.jsx";
import { fetchSurveyTemplatesTypeConfigs } from "reduxToolkit/features/surveyTemplateTypeConfig/SurveyTemplateTypeConfigSlice.js";
import {
  fetchAllPrograms,
  fetchProgramById,
  updateSurveyPlanningTemplate
} from "../../../../reduxToolkit/features/program/ProgramsSlice.js";
import { affectSurveyTemplateToProgram, generateSurveyQuestions } from "reduxToolkit/features/SurveyMapping/SurveyMappingSlice.jsx"
import ErrorModal from "../../../Components/ErrorModal.jsx";

export default function AddSurvey(props) {
  const { Option } = Select;
  const { t } = useTranslation();
  let program_id = props.program_id;
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [handledError, setHandledError] = React.useState("");
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const [templatesTypes, setTemplatesTypes] = React.useState([])

  const fetchTypes = () => {
    dispatch(fetchSurveyTemplatesTypeConfigs()).unwrap().then((res) => {
      setTemplatesTypes(res.filter(type => type.category === "program"
      ))
    })
  }

  useEffect(() => {
    fetchTypes();
  }, []);


  const fetchProgramSurveyTemplates = async (id) => {
    try {
      await dispatch(getProgramSurveyTemplates(id)).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };

  const programSurveyTemplates = useSelector(
    (state) => state.survey.programSurveyTemplates
  );

  function onChangeTypeSurvey(id) {
    console.log(id)
    fetchProgramSurveyTemplates(id);
  }

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    // message.error('Submit failed!');
  };


  const onFinish = async (values) => {
    const resultAction = await dispatch(
      updateSurveyPlanningTemplate({ ...values, program_id })
    );
    console.log("values", { ...values, program_id })

    if (updateSurveyPlanningTemplate.fulfilled.match(resultAction)) {
      // dispatch(generateSurveyQuestions(values.surveyTemplate_ids?.toString()))
      dispatch(fetchProgramById(program_id))
      openNotificationWithIcon("success", `Updated successfully`);
      handleClose();
    } else {
      if (resultAction.payload) {
        setHandledError(resultAction.payload);
        handleOpenErrorModal();
        handleClose();
      } else {
        setHandledError(resultAction.error);
        handleOpenErrorModal();
        handleClose();
      }
    }
  };


  return (
    <div>
      <Button color="primary" onClick={() => {
        handleClickOpen();
      }}>

        {t("Add Survey")}

      </Button>
      <Dialog open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {t("Add Survey Template")}
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={10} sm={10} md={12}>
              <Form
                name="basic"
                layout="vertical"
                //   labelCol={{ span: 8 }}
                //   wrapperCol={{ span: 16 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                scrollToFirstError
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Type")}
                      name='typeId'
                      rules={[
                        {
                          required: true,
                          message: "Please input the type's field",
                        },
                      ]}
                    >
                      <Select
                        onChange={onChangeTypeSurvey}
                        showSearch
                        dropdownStyle={{ zIndex: 10000 }}
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {templatesTypes?.map((type) => (
                          <Option key={type.id} value={type.id}>
                            {type.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Form.Item
                      label={t("Template")}
                      name="surveyTemplate_ids"
                      rules={[
                        {
                          required: true,
                          message: "Please input the template's field",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        dropdownStyle={{ zIndex: 10000 }}
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {programSurveyTemplates?.map((template) => (
                          <Option key={template.id} value={template.id}>
                            {template.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </GridItem>
                </GridContainer>

                {/* </FormControl> */}
                <GridContainer justify="flex-end">
                  <GridItem>
                    <Form.Item>
                      <GridContainer>
                        <GridItem>
                          <Button
                            color="primary"
                            onClick={() => {
                              handleClose();
                            }}
                          >
                            {t("Cancel")}
                          </Button>
                        </GridItem>
                        <GridItem>
                          <Button type="submit" color="primary">
                            {t("Save")}
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </Form.Item>
                  </GridItem>
                </GridContainer>
              </Form>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>

      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
